import { Injectable } from '@angular/core';
import { Provincia } from '../entities/province';
import { HttpClient } from '@angular/common/http';
import { URLS_CONSTANTS } from '../app.constant.url';
import { Comune } from '../entities/comune';
import { RicercaImpianti} from '../entities/ricerca';
import { RicercaPaginata } from '../entities/paginazione-ricerca';
import { TipoImpianto } from '../entities/tipo-impianto';
import { Operazione } from '../entities/operazione';
import { map } from 'rxjs/operators';
import { CER, ElencoCER } from '../entities/cer';
import { StatoEsercizio } from '../entities/stato-esercizio';
import { Configurazione } from '../entities/configurazione';
import { BehaviorSubject, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CGRService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  URL : URLS_CONSTANTS = new URLS_CONSTANTS();

  constructor(private http:HttpClient) { }

  getProvinceLombardia() : Promise<Provincia[]> {
    console.log("URL: "+ this.URL.URL_PROVINCE_LOMBARDIA);
    return this.http.get<Provincia[]>(this.URL.URL_PROVINCE_LOMBARDIA).toPromise();
  }

  getComuniByIstat(istatProvincia: string) : Promise <Comune[]>{
    return this.http.get<Comune[]>(this.URL.URL_COMUNI_BY_ISTAT+'/'+istatProvincia).toPromise();
  }

  getTipiImpianto() : Promise<TipoImpianto[]>{
    return this.http.get<TipoImpianto[]>(this.URL.URL_TIPI_IMPIANTO).toPromise();
  }

  getOperazioni() : Promise<Operazione[]>{
    return this.http.get<Operazione[]>(this.URL.URL_OPERAZIONI).toPromise();
  }

  getCer() : Promise<ElencoCER>{

    if(1==1)
    return new Promise((resolve,error) => {
        let a : ElencoCER = {
            CERLivello1 : [],
            CERLivello2 : [],
            CERLivello3 : []
        };
        resolve(a);
    });

    return this.http.get<CER[]>(this.URL.URL_CER).pipe(map ( response =>{
        const cerLivello1 = response.filter(x => x.idParent == null);
        const cerLivello2 = response.filter(x => cerLivello1.map(cer => cer.idCer).includes(x.idParent));
        const cerLivello3 = response.filter(x => cerLivello2.map(cer => cer.idCer).includes(x.idParent));
        const elencoCER : ElencoCER = {
            CERLivello1 : cerLivello1,
            CERLivello2 : cerLivello2,
            CERLivello3 : cerLivello3
        };
        return elencoCER
    })).toPromise();
  }

  getImpianti(request : RicercaImpianti) : Promise<RicercaPaginata>{
    return this.http.post<RicercaPaginata>(this.URL.URL_RICERCA_IMPIANTI, request).toPromise();
  }

  getReport(request : RicercaImpianti) {
    return this.http.post(this.URL.URL_SCARICA_REPORT, request, {responseType: 'blob'}).toPromise();
  }

  getStatiEsercizio() : Promise<StatoEsercizio[]>{
    return this.http.get<StatoEsercizio[]>(this.URL.URL_STATO_ESERCIZIO).toPromise();
  }

  getCERLivello1(anno:string) : Promise<CER[]>{
     return this.http.get<CER[]>(this.URL.URL_CER_PRIMO_LIVELLO + anno).toPromise();
  }

  getCERLivello2(anno:string) : Promise<CER[]>{
    return this.http.get<CER[]>(this.URL.URL_CER_SECONDO_LIVELLO + anno).toPromise();
 }

 getCERLivello3(anno:string) : Promise<CER[]>{
    return this.http.get<CER[]>(this.URL.URL_CER_TERZO_LIVELLO + anno).toPromise();
 }

 getLinkGeo(): Promise<Configurazione> {
    return this.http.get<Configurazione>(this.URL.URL_CONFIGURAZIONE + 'LINK_GEO').toPromise();
 }

 getMatrice(idUL :number) {
    return this.http.get(this.URL.URL_SCARICA_MATRICE + idUL, {responseType: 'blob'}).toPromise();
  }

  getConfigurazioni(): Observable<Array<Configurazione>> {
    return this.http.get<Array<Configurazione>>(this.URL.URL_CONFIGURAZIONE);
  }

  activeLoading() {
    this._loading.next(true);
  }

  stopLoading() {
    this._loading.next(false);
  }

}
