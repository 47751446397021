import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { RisultatoRicerca } from 'src/app/entities/risultati-ricerca';
import { CGRService } from 'src/app/services/cgrervice.service';

@Component({
    selector: 'app-dettaglio-risultato-ricerca',
    templateUrl: './dettaglio-risultato-ricerca.component.html',
    styleUrls: ['./dettaglio-risultato-ricerca.component.css']
})
export class DettaglioRisultatoRicercaComponent implements OnInit {

    @Input() risultato: RisultatoRicerca;
    
    @Output() tornaARicerca: EventEmitter<boolean> = new EventEmitter<boolean>();

    comune: string = '';
    provincia: string = '';
    comuniIntaressati: string = '';
    tipologieImpiantiDesc: string = '';
    linkGeo: string = '';
    numberFormat: Intl.NumberFormat = new Intl.NumberFormat('it', { minimumFractionDigits: 2 });

    constructor(private service : CGRService) { }

    ngOnInit() {
        this.creaLinkGeo();
        window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });
        if( this.risultato.listaComuni != null && this.risultato.listaComuni.length > 0 ) {
            this.comune = this.risultato.listaComuni[0].nomeComune;
            this.provincia = this.risultato.listaComuni[0].siglaProvincia ? `(${this.risultato.listaComuni[0].siglaProvincia})` : '';
        }
        this.comuniIntaressati = this.risultato.listaComuni.map( x => ` ${x.nomeComune} ${x.siglaProvincia ? '(' + x.siglaProvincia + ')' : ''} `).join(', ');
        this.tipologieImpiantiDesc = this.risultato.tipologieImpianto.map( x => x.descrTipoImpianto ).join(', ');
    }

    creaLinkGeo() {
        this.service.getLinkGeo().then( x => {
            this.linkGeo = x.valoreStringa;
        });
    }

    onClickTornaRisultati() {
        this.tornaARicerca.emit(true);
    }

    onClickScaricaMatrice() {
        this.service.activeLoading();
        this.service.getMatrice(this.risultato.idUL).then( (data) => {
            let downloadURL = window.URL.createObjectURL(data);
            let link = document.createElement('a');
            link.href = downloadURL;
            link.download = "matrice-cer-operazioni.xlsx";
            link.click();
            this.service.stopLoading();
        }); 
    }
}
