<main class="col" [ngStyle]="{'display': (datiCaricati &&  (!showDettaglio)) ? 'block' : 'none'}">
    <h2>
        RICERCA IMPIANTI
    </h2>
    <p>Per ricercare gli impianti è possibile selezionare uno o più criteri per ciascun gruppo</p>
    <form #f="ngForm" >

        <div id="collapseAmbitoTerritoriale" class="collapse-div collapse-left-icon select-wrapper" role="tablist">
            <div class="collapse-header" id="collapseAmbitoTerritorialeHeading">
                <button data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                    <h3>Seleziona ambito territoriale</h3>
                </button>
            </div>
            <div id="collapse5" class="collapse" role="tabpanel" aria-labelledby="heading5">
                <div class="collapse-body">
                    <select ngModel name="ambitoTerritoriale" (change)="onChangeAmbitoTerritoriale(prov.value)">
                        <option value="PROVINCIA">per provincia</option>
                        <option value="COMUNE">per comune</option>
                    </select>
                    <select (change)="onChangeProvincia($event.srcElement.value)" ngModel name="provincia" #prov>
                        <option selected="" value="">Seleziona una provincia</option>
                        <option *ngFor="let provincia of provinceLombardia" [value]="provincia?.istatProvincia">
                            {{provincia?.nomeProvincia}}</option>
                    </select>
                    <select ngModel name="comune" *ngIf="f.value.ambitoTerritoriale == 'COMUNE'">
                        <option selected="" value="">Seleziona un comune</option>
                        <option *ngFor="let comune of comuniProvinciaSelezionata" [value]="comune?.istatComune">
                            {{comune?.nomeComune}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        
        <!-- STATI ESERCIZIO -->
        <div id="collapseStatiEsercizio" class="collapse-div collapse-left-icon" role="tablist">
            <div class="collapse-header" id="collapseStatiEsercizioHeading">
                <button data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                    <h3>Seleziona stato esercizio</h3>
                </button>
            </div>
            <div id="collapse4" class="collapse" role="tabpanel" aria-labelledby="heading4" ngModelGroup="gruppoStatiEsercizio">
                <div class="collapse-body div-form-check">
                    <div class="form-check form-check-inline" *ngFor="let stato of statiEsercizio">
                        <input 
                            id="stato-{{stato?.idStatoEsercizio}}" 
                            type="checkbox" 
                            ngModel name="{{stato?.idStatoEsercizio}}"
                        >
                        <label for="stato-{{stato?.idStatoEsercizio}}">{{stato.statoEsercizio}}</label>
                    </div>
                </div>
            </div>
        </div>
               
        <!-- TIPI IMPIANTO -->
        <div id="collapseTipologiaImpianto" class="collapse-div collapse-left-icon" role="tablist">
            <div class="collapse-header" id="collapseTipologiaImpiantoHeading">
                <button data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                    <h3>Seleziona tipologia impianto</h3>
                </button>
            </div>
            <div id="collapse1" class="collapse" role="tabpanel" aria-labelledby="heading1" ngModelGroup="gruppoTipiImpianto">
                <div class="collapse-body div-form-check">
                    <div class="form-check form-check-inline" *ngFor="let tipo of tipiImpianto">
                        <input 
                            id="tipo-{{tipo?.idTipoImpianto}}" 
                            type="checkbox" 
                            ngModel name="{{tipo?.idTipoImpianto}}"
                        >
                        <label for="tipo-{{tipo?.idTipoImpianto}}">{{tipo.descrizione}}</label>
                    </div>
                </div>
            </div>
        </div>

        <!--  OPERAZIONI  -->
        <div id="collapseOperazioni" class="collapse-div collapse-left-icon" role="tablist">
            <div class="collapse-header" id="collapseOperazioniHeading">
                <button data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                    <h3>Seleziona operazioni</h3>
                </button>
            </div>
            <div id="collapse2" class="collapse" role="tabpanel" aria-labelledby="heading1" ngModelGroup="gruppoOperazioni">
                <div class="collapse-body div-form-check div-form-check-operazioni">
                    <div class="form-check" *ngFor="let operazione of operazioni">
                        <input id="operazione{{operazione?.idOperazione}}" type="checkbox" 
                        name="{{operazione?.idOperazione}}"
                        ngModel
                        >
                        <label
                        class="labelOperazioni"
                        for="operazione{{operazione?.idOperazione}}"
                        data-toggle="tooltip" data-placement="right"
                        title={{operazione.descrizioneOperazione}}
                        >
                            {{operazione.codiceOperazione}}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- CER -->
        <div id="collapseCER" class="collapse-div collapse-left-icon" role="tablist">
            <div class="collapse-header" id="collapseCERHeading">
                <button data-toggle="collapse" data-target="#collapse3" aria-expanded="false"
                    aria-controls="collapseCER">
                    <h3>Seleziona EER</h3>
                </button>
            </div>
            <div id="collapse3" class="collapse" role="tabpanel" aria-labelledby="collapseCERHeading">
                <div class="collapse-body">
                    <app-seleziona-cer [reset]="reset" (eventCambiamentoCerSelezionati)="onChangeCerSelezionati($event)" (resetChange)="handleReset($event)" #cerComponent></app-seleziona-cer>
                </div>
            </div>
        </div>

        <br>

        <div class="input-group-append" style="margin: 10px 0"  id="buttonRicerca">
            <button class="btn btn-primary" type="button" id="button-3" style="margin-right: 20px; margin-left:auto" (click)="resetCer(cerComponent)">
                <svg class="icon icon-white"><use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-close-big"></use></svg>
                Reset
            </button>
            <button class="btn btn-primary" id="button-4" type="submit" (click)="onClickSubmit(f)">
                <svg class="icon icon-white"><use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-search"></use></svg>
                Ricerca
            </button>
        </div>
    </form>
</main>


<main *ngIf="showDettaglio" class="col">
    <app-dettaglio-risultato-ricerca
        [risultato]="dettaglio"
        (tornaARicerca)="chiudiDettaglioImpianto($event)">
    </app-dettaglio-risultato-ricerca>
</main>

<main *ngIf="ricercaPaginata?.content && !showDettaglio" class="col">
    <app-risultati-ricerca-impianti [risultatiRicerca]="ricercaPaginata.content" [statiEsercizio]="statiEsercizio"
        (apriDettaglio)="showDettaglioImpianto($event)" ></app-risultati-ricerca-impianti>
</main>

<main *ngIf="ricercaPaginata?.content && !showDettaglio" class="col">
    <app-paginatore-risultati-ricerca
        [numeroPagine]="ricercaPaginata.totalPages"
        [paginaCorrente]="ricercaPaginata.number + 1"
        [ricerca]="request"
        (cambiaPagina)="ricercaImpianti($event)"
        (effettuaNuovaRicerca)="effettuaNuovaRicerca($event)"
    >
    </app-paginatore-risultati-ricerca>
</main>