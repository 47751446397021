import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigurazioneMapping } from '../config/config.enum';
import { Configurazione } from '../entities/configurazione';
import { MenuItem, MenuItems } from '../entities/menuItems.interface';
import { menuItemsConfig } from '../config/menu-items.config';

@Component({
    selector: 'app-header-interna',
    templateUrl: './header-interna.component.html',
    styleUrls: ['./header-interna.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderInternaComponent implements OnChanges {
    @Input() config: Array<Configurazione>;
    @Output() pageRequested = new EventEmitter<{ name: String }>();
    menuItems: MenuItems = menuItemsConfig;
    sitoRegione: string;
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.config && changes.config.currentValue) {
          const config = changes['config'].currentValue;
          this.sitoRegione = config.find(c => c.codice === ConfigurazioneMapping.urlSitoRegione)?.valoreStringa;

          for(let c of changes['config'].currentValue){

            this.menuItems = this.menuItems.map(menu => {
                if(c?.codice === menu?.link) {
                    menu = {...menu, href: c.valoreStringa }
                }
                
                return menu;
            })
          }
        }
    }

    onClickPagina(menu : MenuItem){
        if(menu.href?.includes('http')) {
            window.open(menu.href, menu.target);
        } else {
            this.pageRequested.emit({name: menu.link});
        }
    }


}
