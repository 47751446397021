import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderInternaComponent } from './header-interna/header-interna.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { RicercaImpiantiComponent } from './ricerca-impianti/ricerca-impianti.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { DettaglioRisultatoRicercaComponent } from './ricerca-impianti/dettaglio-risultato-ricerca/dettaglio-risultato-ricerca.component';
import { PaginatoreRisultatiRicercaComponent } from './paginatore-risultati-ricerca/paginatore-risultati-ricerca.component';
import { RisultatiRicercaImpiantiComponent } from './ricerca-impianti/risultati-ricerca-impianti/risultati-ricerca-impianti.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule} from '@angular/material/select';
import { CriteriLocalizzativiComponent } from './criteri-localizzativi/criteri-localizzativi.component';
import { DatiCartograficiComponent } from './dati-cartografici/dati-cartografici.component';
import { SelezionaCerComponent } from './ricerca-impianti/seleziona-cer/seleziona-cer.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MenuComponent } from './components/menu/menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from './spinner/spinner.module';


@NgModule({
  declarations: [
    AppComponent,
    HeaderInternaComponent,
    SidebarComponent,
    ContentComponent,
    FooterComponent,
    RicercaImpiantiComponent,
    DettaglioRisultatoRicercaComponent,
    PaginatoreRisultatiRicercaComponent,
    RisultatiRicercaImpiantiComponent,
    CriteriLocalizzativiComponent,
    DatiCartograficiComponent,
    SelezionaCerComponent,
    MenuComponent
    
  ],
  imports: [
    BrowserModule, HttpClientModule, FormsModule, MatSelectModule, BrowserAnimationsModule,
    MatTableModule, MatButtonModule, MatCardModule, MatInputModule, MatListModule, MatToolbarModule,
    MatAutocompleteModule, ReactiveFormsModule, NgbModule, SpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
