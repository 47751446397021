import { AfterViewChecked, AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CER } from '../entities/cer';
import { Comune } from '../entities/comune';
import { Operazione } from '../entities/operazione';
import { RicercaPaginata } from '../entities/paginazione-ricerca';
import { Provincia } from '../entities/province';
import { StatoEsercizio } from '../entities/stato-esercizio';
import { TipoImpianto } from '../entities/tipo-impianto';
import { CGRService } from '../services/cgrervice.service';
import { DatiRicercaService } from '../services/dati-ricerca.service';
import { RicercaImpianti } from '../entities/ricerca';
import { RisultatoRicerca } from '../entities/risultati-ricerca';
import { distinctUntilChanged, tap, takeUntil } from 'rxjs';
import { UnsubscriptionHandler } from '../utilities/unsubscription-handler';
declare var $: any;

@Component({
    selector: 'app-ricerca-impianti',
    templateUrl: './ricerca-impianti.component.html',
    styleUrls: ['./ricerca-impianti.component.css']
})
export class RicercaImpiantiComponent extends UnsubscriptionHandler implements OnInit, AfterViewChecked {

    datiCaricati: boolean = false;
    datiRicercaImpiantoCaricati: boolean = false;
    isLoadingDatiRicerca: boolean = false;

    provinceLombardia: Provincia[] = [];
    comuniLombardia: Comune[] = [];

    comuniProvinciaSelezionata: Comune[] = [];
    ambitoTerritoriale: String = 'PROVINCIA';

    ricercaPaginata: RicercaPaginata;

    statiEsercizio: StatoEsercizio[] = [];
    tipiImpianto: TipoImpianto[] = [];
    operazioni: Operazione[] = [];

    elencoCerSelezionati: CER[] = [];

    showDettaglio: boolean = false;
    dettaglio: RisultatoRicerca;

    request: RicercaImpianti = null;

    @ViewChild('f') form: NgForm;
    reset: boolean = false;

    constructor(private service: CGRService, private datiRicerca: DatiRicercaService) {
        super();
     }


    async ngOnInit() {
        await this.caricaDati().then(x => {
            this.datiCaricati = true;
            console.log(this.form);
            this.form.controls.ambitoTerritoriale.setValue('PROVINCIA');
        });
    }

    ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    async caricaDati() {
        this.provinceLombardia = await this.datiRicerca.getProvinceLombardia();
        this.comuniLombardia = await this.datiRicerca.getComuniLombardia();
        this.statiEsercizio = await this.datiRicerca.getStatiEsercizio();
        this.tipiImpianto = await this.datiRicerca.getTipiImpianto();
        this.operazioni = await this.datiRicerca.getOperazioni();
        //this.cer = await this.datiRicerca.getCer();
    }

    onChangeProvincia(istatProvincia: string) {
        this.comuniProvinciaSelezionata = this.comuniLombardia.filter(x => x.istatProvincia == istatProvincia);
    }

    onChangeAmbitoTerritoriale(provincia: any) {
        if (this.ambitoTerritoriale === 'COMUNE') {
            if (provincia !== '') {
                this.onChangeProvincia(provincia);
            }
            else {
                this.comuniProvinciaSelezionata = null;
            }
        }
    }

    ricercaImpianti(pagina: number) {
        this.service.activeLoading();
        this.ricercaPaginata = null;
        //this.datiRicercaImpiantoCaricati = false;
        this.isLoadingDatiRicerca = true;
        this.request.pagina = pagina;

        this.service.getImpianti(this.request).then(x => {
            this.ricercaPaginata = x
            this.datiCaricati = true;
            this.datiRicercaImpiantoCaricati = true;
            this.isLoadingDatiRicerca = false;
            this.service.stopLoading();
            setTimeout(function () {
                document.getElementById("buttonRicerca").scrollIntoView({ behavior: 'smooth' });
            }, 300);
        });
    }

    showDettaglioImpianto(id: RisultatoRicerca) {

        this.showDettaglio = true;
        this.dettaglio = id;
        this.datiRicercaImpiantoCaricati = false;

    }

    chiudiDettaglioImpianto(event?: any) {
        this.showDettaglio = false;
        this.datiRicercaImpiantoCaricati = true;

        setTimeout(function () {
            document?.getElementById("buttonRicerca").scrollIntoView({ behavior: 'smooth' });
        }, 300);
    }

    onClickSubmit(form: NgForm) {
        this.datiRicercaImpiantoCaricati = false;
        this.isLoadingDatiRicerca = true;
        this.service.activeLoading();

        const request: RicercaImpianti = {
            istatProvincia: null,
            istatComune: null,
            idStatiEsercizio: [],
            idTipoImpianti: [],
            idOperazioni: [],
            idCer: [],
            pagina: 0
        };

        if (this.form.controls.ambitoTerritoriale.value == 'PROVINCIA')
            request.istatProvincia = form.value.provincia;

        if (this.form.controls.ambitoTerritoriale.value  == 'COMUNE')
            if(form.value.comune) request.istatComune = form.value.comune;
            else request.istatProvincia = form.value.provincia;

        let statiEsercizio = form.value.gruppoStatiEsercizio;
        for (const [key, value] of Object.entries(statiEsercizio)) {
            if (value == true)
                request.idStatiEsercizio.push(key);
        }

        let tipiImpianto = form.value.gruppoTipiImpianto;
        for (const [key, value] of Object.entries(tipiImpianto)) {
            if (value == true)
                request.idTipoImpianti.push(key);
        }

        let operazioni = form.value.gruppoOperazioni;
        for (const [key, value] of Object.entries(operazioni)) {
            if (value == true)
                request.idOperazioni.push(key);
        }

        this.elencoCerSelezionati.forEach(x => request.idCer.push(x.idCer.toString()));

        this.request = request;

        this.service.getImpianti(request).then(x => {
            this.ricercaPaginata = x;
            this.datiRicercaImpiantoCaricati = true;
            this.isLoadingDatiRicerca = false;
            this.service.stopLoading();
        });

    }

    effettuaNuovaRicerca(event?: any) {
        this.request = null;
        this.datiRicercaImpiantoCaricati = false;
        this.showDettaglio = false;
    }

    onChangeCerSelezionati(cer: CER[]) {
        this.elencoCerSelezionati = cer;
    }

    resetCer(cerComponent: any) {
        cerComponent.elencoCerSelezionati = [];

        // this.elencoCerSelezionati = [];
        //this.ambitoTerritoriale === 'PROVINCIA'
        this.elencoCerSelezionati = [];
        this.form.onReset();
        this.form.controls.ambitoTerritoriale.setValue('PROVINCIA');
        this.form.controls.provincia?.setValue('')
        this.form.controls.comune?.setValue('');
        this.form.controls.gruppoOperazioni.patchValue({});
        this.form.controls.gruppoStatiEsercizio.patchValue({});
        this.form.controls.gruppoTipiImpianto.patchValue({});
        this.comuniProvinciaSelezionata = null;
        this.elencoCerSelezionati = [];
        this.request = null;
        this.reset = true;
    }

    handleReset = (value: boolean) => {
        this.reset = value;
    }

}
