import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Configurazione } from '../entities/configurazione';
import { ConfigurazioneMapping } from '../config/config.enum';

@Component({
  selector: 'app-criteri-localizzativi',
  templateUrl: './criteri-localizzativi.component.html',
  styleUrls: ['./criteri-localizzativi.component.css']
})
export class CriteriLocalizzativiComponent implements OnChanges {
  @Input() config: Array<Configurazione>;
  linkCriloc: string;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.config && changes?.config.currentValue) {
      const config = changes['config'].currentValue;
      this.linkCriloc = config.find(c => c.codice === ConfigurazioneMapping.linkCriloc)?.valoreStringa;
    }
  }

  apriLink = () => {
    window.open(this.linkCriloc,'_blank');
  }

}
