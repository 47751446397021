<a href="javascript:void(0);" tab-index="0" class="spaces" data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">
    <span class="navbar-toggler-icon"></span>
</a>
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasWithBackdrop"
    aria-labelledby="offcanvasWithBackdropLabel" style="background: #ffffff;">
    <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="link-list-wrapper">
        <ng-container *ngFor="let menu of menuItems; let index = index">
            <div id="{{ 'collapseDiv' + index}}" class="collapse-background-active">
                <div class="collapse-header" id="heading1-sc1">
                    <ng-container *ngIf="!menu.subMenu; else subMenuTpl">
                        <a class="primary-menu spaces" role="button" data-bs-dismiss="offcanvas" (click)="onClickPagina(menu)">{{menu.label}}</a>
                    </ng-container>
                    <ng-template #subMenuTpl>
                        <button data-toggle="collapse" [attr.data-target]="'#' + menu?.dataTarget" aria-expanded="false" aria-labelledby="heading1-sc1"
                        [attr.aria-controls]="menu?.dataTarget">
                        {{ menu.label}}
                        </button>
                        <div id="{{ menu?.dataTarget }}" class="collapse" role="region" aria-labelledby="heading1-sc1">
                            <div class="collapse-body-custom">
                                <div class="sub-menu">
                                    <a *ngFor="let subMenu of menu.subMenu" href="javascript:void(0);" class="list-item spaces"
                                        role="button" data-bs-dismiss="offcanvas"><span (click)="onClickPagina(subMenu)">{{ subMenu.label }}</span></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-container>
    </div>
</div>