import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Form, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ElencoCER, CER } from 'src/app/entities/cer';
import { CGRService } from 'src/app/services/cgrervice.service';
import { DatiRicercaService } from 'src/app/services/dati-ricerca.service';
import {map, startWith} from 'rxjs/operators';


@Component({
    selector: 'app-seleziona-cer',
    templateUrl: './seleziona-cer.component.html',
    styleUrls: ['./seleziona-cer.component.css']
})
export class SelezionaCerComponent implements OnInit, OnChanges {


    elencoCerLivello1: CER[] = [];
    elencoCerLivello2: CER[] = [];
    elencoCerLivello2Filtrati: CER[] = [];
    elencoCerLivello3: CER[] = [];
    elencoCerLivello3Filtrati: CER[] = [];

    /* Legati alle select per l'inserimento di un nuovo elemento*/
    CerLivello1Selezionato: CER = new CER();
    CerLivello2Selezionato: CER = new CER();
    CerLivello3Selezionato: CER = new CER();

    elencoCerSelezionati: CER[] = [];

    annoSelezionato: string = "2021";

    isLoadingData: boolean = false;

    elencoCerLivello3FiltratiAutoComplete: CER[] = [];
    formControlAutoComplete : FormControl = new FormControl('');

    @Input() reset!: boolean;
    @Output() eventCambiamentoCerSelezionati : EventEmitter<CER[]> = new EventEmitter<CER[]>();
    @Output() resetChange : EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor(private service: CGRService, private datiRicerca: DatiRicercaService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['reset']?.currentValue){
            this.annoSelezionato = '2021';
            this.resetChange.emit(false);
            this.ngOnInit();
        }
    }

    ngOnInit() {
        this.isLoadingData = true;

        
        this.resetCerSelezionati();

        this.elencoCerSelezionati = [];

        this.elencoCerLivello1 = [];
        this.elencoCerLivello2 = [];
        this.elencoCerLivello2Filtrati = [];
        this.elencoCerLivello3 = [];
        this.elencoCerLivello3Filtrati = [];

        

        this.caricaDati();
    }

    async caricaDati() {
        Promise.all([
            this.caricaCerLivello1(this.annoSelezionato),
            this.caricaCerLivello2(this.annoSelezionato),
            this.caricaCerLivello3(this.annoSelezionato)
        ]).then(x => {
            this.isLoadingData = false
            this.elencoCerLivello3FiltratiAutoComplete = this.elencoCerLivello3;

            this.formControlAutoComplete.valueChanges.subscribe(value =>{
               this.elencoCerLivello3FiltratiAutoComplete = this._filter(value);
            });
            
              
        });
    }

    private _filter(value: string): CER[] {
        const filterValue = value.toLowerCase();
        let arrayFiltro = this.elencoCerLivello3;

        if(this.CerLivello1Selezionato.idCer == -1)
            arrayFiltro = this.elencoCerLivello3;

        if(this.CerLivello1Selezionato.idCer != -1 && this.CerLivello2Selezionato.idCer == -1 ){
            const cer2 = this.elencoCerLivello2.filter(x => x.idParent == this.CerLivello1Selezionato.idCer);
            arrayFiltro =  this.elencoCerLivello3.filter(x => cer2.findIndex(y => y.idCer == x.idParent) != -1);
        }

        if(this.CerLivello1Selezionato.idCer != -1 && this.CerLivello2Selezionato.idCer !- -1 )
            arrayFiltro = this.elencoCerLivello3Filtrati;
              
        if(filterValue == '')
            return arrayFiltro;

        return arrayFiltro.filter(x =>{
             return x.descrizioneCer.includes(filterValue) || x.codiceCer.includes(filterValue)
        });
      }

    async caricaCerLivello1(anno: string) {
        this.elencoCerLivello1 = await this.service.getCERLivello1(anno);
    }

    async caricaCerLivello2(anno: string) {
        this.elencoCerLivello2 = await this.service.getCERLivello2(anno);
    }

    async caricaCerLivello3(anno: string) {
        this.elencoCerLivello3 = await this.service.getCERLivello3(anno);
    }

    onSelezionaCerLivello1() {
        this.elencoCerLivello2Filtrati = this.elencoCerLivello2.filter(x => x.idParent == this.CerLivello1Selezionato.idCer);
        this.CerLivello2Selezionato.idCer = -1;
        this.CerLivello3Selezionato.idCer = -1;
        this.formControlAutoComplete.setValue('');
    }

    onSelezionaCerLivello2() {
        this.elencoCerLivello3Filtrati = this.elencoCerLivello3.filter(x => x.idParent == this.CerLivello2Selezionato.idCer);
        this.CerLivello3Selezionato.idCer = -1;
        this.formControlAutoComplete.setValue('');    
    }

    onSelezionaCerLivello3(){
        this.formControlAutoComplete.setValue('');
    }

    onChangeAnnoCatalogo(e: any) {
        this.annoSelezionato = e.srcElement.value;
        this.ngOnInit();
    }

    onClickAddCerLivello() {
        if(
            this.CerLivello1Selezionato.idCer == -1 ||
            this.CerLivello2Selezionato.idCer == -1 ||
            this.CerLivello3Selezionato.idCer == -1 ||
            this.elencoCerSelezionati.findIndex(x => x.idCer == this.CerLivello3Selezionato.idCer) != -1
        ) return;

        

        let cer = this.elencoCerLivello3Filtrati.find(x => x.idCer == this.CerLivello3Selezionato.idCer);
        this.elencoCerSelezionati.push(cer);
        //this.resetCerSelezionati();
        this.eventCambiamentoCerSelezionati.emit(this.elencoCerSelezionati);

        this.CerLivello3Selezionato.idCer = -1;
        this.formControlAutoComplete.setValue(''); 
    }

    onClickAggiuntiTutti( livelloCer : number){

        if(livelloCer == 1 && this.CerLivello1Selezionato.idCer == -1)
            return;
        if(livelloCer == 2 && this.CerLivello2Selezionato.idCer == -1)
            return;

        
        if(livelloCer == 1 ){
            const cerDaAggiungere = this.elencoCerLivello3.filter(cerLivello3 =>{

                //check che il cer di livello 3 sia figlio di uno dei cer di livello 2 filtrati 
                //NB quando passiamo da qui l'utente ha selezionato sempre almeno un cer di livello 1.
                if(this.elencoCerLivello2Filtrati.findIndex(cerLivello2 => cerLivello2.idCer == cerLivello3.idParent) == -1)
                    return false;
                //check che il cer di livello 3 che si vuole aggiungere non sia già presente
                 return this.elencoCerSelezionati.findIndex(x => x.idCer == cerLivello3.idCer) == -1
            });
            cerDaAggiungere.forEach(x => this.elencoCerSelezionati.push(x));           
            this.resetCerSelezionati(); 
        }  

        if(livelloCer == 2){
            const cerDaAggiungere = this.elencoCerLivello3Filtrati.filter(cerLivello3 =>{
                return this.elencoCerSelezionati.findIndex(x => x.idCer == cerLivello3.idCer) == -1
            });
            cerDaAggiungere.forEach(x => this.elencoCerSelezionati.push(x)); 
            this.CerLivello2Selezionato.idCer = -1;
            this.formControlAutoComplete.setValue('');
        }  

        this.eventCambiamentoCerSelezionati.emit(this.elencoCerSelezionati);

    }

    resetCerSelezionati(){
        this.CerLivello1Selezionato.idCer = -1;
        this.CerLivello2Selezionato.idCer = -1;
        this.CerLivello3Selezionato.idCer = -1;
        this.formControlAutoComplete.setValue('');
    }

    onClickEliminaCerSelezionato(idCer : number){
        this.elencoCerSelezionati = this.elencoCerSelezionati.filter(x => x.idCer != idCer);
        this.eventCambiamentoCerSelezionati.emit(this.elencoCerSelezionati);
    }

    onClickSuOptionAutoComplete(idCer3 : number){
        let idCer2 = this.elencoCerLivello3.find(x => x.idCer == idCer3).idParent;
        let idCer1 = this.elencoCerLivello2.find(x => x.idCer == idCer2).idParent;

        this.elencoCerLivello3Filtrati = this.elencoCerLivello3.filter(x => x.idParent == idCer2);
        this.elencoCerLivello2Filtrati = this.elencoCerLivello2.filter(x => x.idParent == idCer1);

        this.CerLivello3Selezionato.idCer = idCer3;
        this.CerLivello2Selezionato.idCer = idCer2;
        this.CerLivello1Selezionato.idCer = idCer1;
    }
}
