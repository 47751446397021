import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Configurazione } from './entities/configurazione';
import { CGRService } from './services/cgrervice.service';
import { DatiRicercaService } from './services/dati-ricerca.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    config$!: Observable<Array<Configurazione>>;
    loading$!: Observable<boolean>;

    constructor(private datiricerca: DatiRicercaService,  private cgrService: CGRService) {
        this.datiricerca.caricaDati();
        this.config$ = this.cgrService.getConfigurazioni();
        this.loading$ = this.cgrService.loading$;
    }


    isHomepageAttiva: boolean = true;
    isRicercaImpAttiva: boolean = false;
    isDatiGeoAttiva: boolean = false;
    isCriteriPaginaAttiva : boolean = false;


    onClickPagina(pagina: { name: string }) {
        console.log("CLICK IN:" + pagina.name);

        if (pagina.name === "HOME")
            this.attivaHomepage();
        if (pagina.name === "RICERCA")
            this.attivaRicerca();
        if(pagina.name === "DATI_CARTOGRAFICI")
            this.attivaDatiCartografici();
        if(pagina.name === "CRITERI_LOCALIZZATIVI")
            this.attivaCriteriLocalizzativi();
    }

    attivaHomepage() {
        this.isHomepageAttiva = true;
        this.isRicercaImpAttiva = false;
        this.isDatiGeoAttiva = false;
        this.isCriteriPaginaAttiva = false;
    }

    attivaRicerca() {
        this.isHomepageAttiva = false;
        this.isRicercaImpAttiva = true;
        this.isDatiGeoAttiva = false;
        this.isCriteriPaginaAttiva = false;
    }

    attivaDatiCartografici() {
        this.isHomepageAttiva = false;
        this.isRicercaImpAttiva = false;
        this.isDatiGeoAttiva = true;
        this.isCriteriPaginaAttiva = false;
    }

    attivaCriteriLocalizzativi(){
        this.isHomepageAttiva = false;
        this.isRicercaImpAttiva = false;
        this.isDatiGeoAttiva = false;
        this.isCriteriPaginaAttiva = true;
    }
}
