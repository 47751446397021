<main class="col">
    <h2>CHE COS'È IL C.G.R. WEB</h2>
    <p>
        Il C.G.R. Web (Catasto Georeferenziato impianti Rifiuti) è un database condiviso da Regione Lombardia e Province lombarde, aggiornato costantemente che contiene i dati tecnici ed amministrativi relativi a:
    </p>
    <ul>
    	<li>tutti gli impianti, compresi quelli mobili, autorizzati ad effettuare operazioni di gestione dei rifiuti ai sensi degli art. 208, 209, 211, 214, 215, 216 e art. 29-sexies del D.lgs. 152/2006;</li>
    	<li>agli impianti a fonte rinnovabile alimentati anche parzialmente da "biomasse rifiuti" (D.lgs. 387/2003);</li>
    	<li>agli impianti autorizzati al trattamento in deroga dei rifiuti liquidi negli impianti di depurazione acque reflue urbane, ai sensi dell'art. 110 del D.lgs. 152/2006.</li>
    </ul>

    <h2>CHI ALIMENTA I CONTENUTI</h2>
    <p>
        Le informazioni del C.G.R. sono aggiornate dagli Enti competenti al rilascio delle autorizzazioni stabiliti dalla l.r. 26/2003 (Regione Lombardia e Province).
    </p>

    <h2>LA VERSIONE PUBBLICA DEL C.G.R. WEB</h2>
    <p>
		Il CGR Web rende pubblicamente consultabili le informazioni relative a tutti siti impiantistici ("unità locali") in esercizio o comunque autorizzati presenti in Lombardia, quali ad es. dati amministrativi, potenzialità, operazioni di trattamento e codici dei rifiuti autorizzati (riferiti all’Elenco Europeo dei Rifiuti – EER) autorizzati, nonché la possibilità di visualizzare su mappa la loro posizione geografica.
    </p>

    <h2>
        AVVERTENZE
    </h2>
    <p>
        I dati ufficiali delle autorizzazioni relative agli impianti di trattamento dei rifiuti sono, in ogni caso, quelli riportati negli atti autorizzativi rilasciati dai diversi Enti competenti.
    </p>

</main>