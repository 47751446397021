import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class UnsubscriptionHandler implements OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
//void perche tipo is useless, quando distrutto component, emmette un valore e completa l'observable
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}