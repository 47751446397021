<main class="col-12 col-md-9 col-xl-8">
    <h2>CRITERI LOCALIZZATIVI IMPIANTI RIFIUTI</h2>
    <p>
		L’aggiornamento del Programma Regionale di Gestione dei Rifiuti (PRGR) approvato con d.g.r. 6408 del 23/05/2022, come stabilito dall’art. 199 del D. Lgs 152/2006, indica i criteri regionali per l'individuazione delle aree idonee e non idonee alla localizzazione degli impianti di trattamento dei rifiuti; le Province, sulla base dei Piani Territoriali di Coordinamento Provinciali, possono poi individuare criteri aggiuntivi che devono essere approvati dalla Regione.
	</p>
    <p>
		I criteri sono normati dagli art. 8 e 9 delle Norme Tecniche di Attuazione (NTA) del PRGR e descritti nella relativa Appendice (Cfr. Sezione "Informazioni" del Viewer) e si applicano, secondo quanto riportato nell’Appendice 1, alle istanze di progetti di trattamento di rifiuti presentati successivamente all’entrata in vigore del P.R.G.R.
	</p>
    <p>
		Regione definisce i “Criteri per l'individuazione”, da parte delle Province e della Città Metropolitana di Milano, delle aree non idonee alla localizzazione degli impianti di smaltimento e di recupero dei rifiuti. Le Province e la Città Metropolitana di Milano devono individuare, nel rispetto dei criteri generali stabiliti dalla Regione e sulla base delle previsioni del Piano Territoriale di Coordinamento Provinciale 1 (PTCP) o, per la Città metropolitana di Milano, in base alle previsioni degli specifici strumenti di pianificazione territoriale, e delle previsioni di cui all'articolo 199 comma 3 del Dlgs 152/06, le zone non idonee alla localizzazione degli impianti di recupero e di smaltimento dei rifiuti, nonché delle zone idonee alla localizzazione di tali impianti. I criteri provinciali possono contemplare elementi di salvaguardia aggiuntiva rispetto ai sovraordinati criteri regionali e non possono in ogni caso essere meno prescrittivi dei criteri stabiliti nel P.R.G.R.
	</p>
	<p>
		Sono stati approvati da Regione Lombardia alcuni criteri provinciali aggiuntivi con i seguenti provvedimenti:
	</p>
	<ul>
		<li>Provincia di Pavia: d.g.r. n. 5204 del 23/05/2016;</li>
		<li>Provincia di Lodi: d.g.r. n. 6206 del 08/02/2017;</li>
		<li>Provincia di Cremona: d.g.r. n. 6306 del 06/03/2017;</li>
		<li>Provincia di Mantova: d.g.r. n. 6308 del 06/03/2017;</li>
		<li>Provincia di Monza-Brianza: d.g.r. n. 7701 del 12/01/2018;</li>
		<li>Provincia di Sondrio: d.g.r. n. 94 del 07/05/2018;</li>
		<li>Provincia di Bergamo: d.g.r. n. 5992 del 21/02/2022;</li>
		<li>Città Metropolitana di Milano: d.g.r. n. 147 del 21/05/2018.</li>
	</ul>
	<p>
		Sono assoggettati ai criteri:
	</p>
	<ul>
		<li>Le discariche (D1, D5),</li>
		<li>Gli impianti di incenerimento (D10, R1),</li>
		<li>Gli impianti di trattamento dei rifiuti (D2, D3, D4, D6, D7, D8, D9, D12, D13, R2, R3, R4, R5, R6, R7, R8, R9, R11, R12),</li>
		<li>Le messe in riserva (R13),</li>
		<li>I depositi preliminari (D15)</li>
		<li>L’adeguamento volumetrico senza modifica dei codici rifiuti riferiti all’Elenco Europeo dei Rifiuti (EER).</li>
	</ul>
	
	<h2>TIPOLOGIE DI CRITERI</h2>
	<p>
		I criteri previsti sono di quattro tipologie:
	</p>
	<ul>
		<li>ESCLUDENTE: esclude la possibilità di realizzare nuovi impianti o la modifica degli impianti esistenti;</li>
		<li>PENALIZZANTE: non esclude la possibilità di realizzare nuovi impianti o la modifica degli impianti esistenti, in ogni caso devono essere preventivamente acquisite le necessarie autorizzazioni/pareri. Il criterio implica una particolare attenzione nella realizzazione delle opere, in virtù delle sensibilità ambientali/paesaggistiche che hanno generato l’indicazione; l’ente competente autorizza solo a fronte di adeguate misure di mitigazione e compensazione e/o di piani di monitoraggio specifici;</li>
		<li>PREVALENTI: esprime la possibilità, da valutarsi in fase di presentazione dell’istanza, da parte dell’autorità competente, di superare alcuni specifici criteri escludenti, secondo quanto previsto al par. 1.6.8. I criteri escludenti superati dalla presenza di quelli prevalenti dovranno comunque essere valutati in fase istruttoria dell’autorità competente come criteri penalizzanti.</li>
		<li>PREFERENZIALE: esprime, sulla base di informazioni e/o di considerazioni aggiuntive di natura logistico/economica/strategica/ambientale, la preferenzialità del sito.</li>
	</ul>
	<p>
		I criteri vengono ulteriormente distinti in base alla tipologia di impianto che si intende realizzare; il PRGR effettua le seguenti distinzioni:
	</p>
	<ul>
		<li>Criteri escludenti per tutte le tipologie impiantistiche;</li>
		<li>Criteri escludenti per le sole discariche;</li>
		<li>Criteri escludenti per i soli impianti di incenerimento;</li>
		<li>Criteri penalizzanti per tutte le tipologie impiantistiche;</li>
		<li>Criteri penalizzanti per i soli impianti di incenerimento.</li>
	</ul>
	
	<h2>QUALI INFORMAZIONI È POSSIBILE OTTENERE DAL VIEWER</h2>
	<p>
		Il viewer geografico rappresenta uno strumento di supporto agli operatori di settore (Amministrazioni pubbliche e privati) coinvolti nelle istruttorie per l'autorizzazione alla realizzazione o modifica di impianti di trattamento dei rifiuti, nelle quali la verifica della fattibilità o meno degli impianti stessi in virtù dei criteri localizzativi rappresenta un passaggio necessario.
	</p>
	<p>
		L’individuazione cartografica dei criteri ha valore puramente indicativo e di supporto all’istruttoria la verifica deve essere puntuale per ciascuna istanza. Per avere informazioni complete in merito alla normativa di riferimento relativa ai criteri per l'individuazione delle aree idonee e non idonee alla localizzazione degli impianti di trattamento dei rifiuti e sul loro ambito di applicazione (alcune tipologie impiantistiche sono ad esempio escluse).
	</p>
	<p>
		Nel viewer sono evidenziate solamente le aree del territorio lombardo caratterizzate da criteri escludenti e/o penalizzanti, visualizzabili a partire dalla scala 1: 50.000. Le aree "escludenti" sono colorate di colore rosso, quelle "penalizzanti" in giallo.
	</p>
	<p>
		Le informazioni messe a disposizione dallo strumento sono organizzate su diversi "layer"(strati) cartografici:	
	</p>
	<ul>
		<li>Aree escludenti tutte le tipologie di impianti (diversi da discariche e inceneritori): sono le aree dove è presente almeno un criterio localizzativo escludente, indipendentemente dalla tipologia di impianto;</li>
		<li>Aree escludenti discariche: sono le aree dove è presente almeno un criterio localizzativo escludente relativamente ai soli impianti di discarica;</li>
		<li>Aree escludenti impianti incenerimento: sono le aree dove è presente almeno un criterio localizzativo escludente relativamente ai soli impianti di incenerimento.</li>
	</ul>
	
	<h2>COME PROCEDERE NELL'ANALISI DEI CRITERI</h2>
	<p>
		Per un'informazione completa sulla zona indagata è necessario iniziare l'analisi rendendo visibili tutti i layer disponibili (escludenti e penalizzanti, areali e non areali) relativi alla tipologia impiantistica di interesse. Cliccando su un punto qualsiasi della mappa della Lombardia, è possibile avere informazioni riguardo ai singoli criteri escludenti e/o penalizzanti che interessano il sito. È possibile anche disegnare sulla mappa il poligono di un impianto che si vuole realizzare o del quale si sta valutando la fattibilità, misurare distanze e disegnare buffer, ecc. Per una guida dettagliata all'utilizzo del viewer e dei relativi strumenti, si rimanda al manuale tecnico disponibile nella sezione "Informazioni".
	</p>
	
	<h2>AVVERTENZE</h2>
	<p>
		Il viewer ha funzione di supporto tecnico e non valore probatorio: si rimanda alla cartografia e documentazione ufficiale messa a disposizione dalle autorità competenti alla definizione dei vincoli territoriali e ambientali.
	</p>
	<p>
		La valutazione caso per caso e sito specifica finalizzata ad accertare l'eventuale presenza di criteri non cartografati (ad esempio la distanza da siti sensibili ecc...) è sempre richiesta.
	</p>
	<p>
		Nella sezione "Informazioni" viene messo a disposizione il dettaglio delle fonti cartografiche utilizzate per l’alimentazione del viewer e vengono specificati i criteri localizzativi per i quali sono disponibili o meno i relativi dati cartografici.
	</p>
	<!-- <a href="{{ linkCriloc }}" target="_blank"><p>vai al Viewer</p></a> -->
	<button class="btn btn-primary" (click)="apriLink()">VAI AL VIEWER</button>
</main>