import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigurazioneMapping } from '../config/config.enum';
import { Configurazione } from '../entities/configurazione';

@Component({
  selector: 'app-dati-cartografici',
  templateUrl: './dati-cartografici.component.html',
  styleUrls: ['./dati-cartografici.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatiCartograficiComponent implements OnChanges {
  @Input() config: Array<Configurazione>;
  linkViewer: string = '';
  linkDownload: string = '';

  constructor() {}

   ngOnChanges(changes: SimpleChanges): void {
    if(changes.config && changes.config.currentValue) {
      const config: Array<Configurazione> = changes['config'].currentValue;
      config.forEach(config => {
        if(config.codice === ConfigurazioneMapping.linkViewer)
          this.linkViewer = config.valoreStringa;
        if(config.codice === ConfigurazioneMapping.linkDownload)
          this.linkDownload = config.valoreStringa;
      })
    }
  }

  apriLink(param: string ): void {
    const link: string = param === 'VIEWER' ? this.linkViewer : this.linkDownload;
    window.open(link,'_blank');
  }

}
