
<div class="row">
    <div class="col-md-12 cgr-table-container">
        <!-- inizio tabella CER-->
        <div>
            <label style="font-size: 0.9rem">Tipologia di rifiuti trattati</label>
            <div class="form-check form-check-inline">
                <input [(ngModel)]="annoSelezionato" name="gruppo2" type="radio" id="radio4" (change)="onChangeAnnoCatalogo($event)" value="2002">
                <label for="radio4">Catalogo 2002</label>
            </div>
            <div class="form-check form-check-inline">
                <input [(ngModel)]="annoSelezionato" name="gruppo2" type="radio" id="radio5"   (change)="onChangeAnnoCatalogo($event)" value="2015">
                <label for="radio5">Catalogo 2015</label>
            </div>
            <div class="form-check form-check-inline">
                <input [(ngModel)]="annoSelezionato" name="gruppo2" type="radio" id="radio6" checked (change)="onChangeAnnoCatalogo($event)" value="2021">
                <label for="radio6">Catalogo 2021</label>
            </div>
        </div>

        <div class="container" *ngIf="isLoadingData" style="margin-top:15px">
            <div class="progress-spinner progress-spinner-active">
            </div>
        </div>

        <table id="tabellaCer" class="table table-striped table-bordered table-hover" style="margin-top:15px" *ngIf="!isLoadingData">
            <thead id="tabellaCerHead" class=" pt-0 mt-0 pb-0 mb-0">
                <tr class="thead-light">
                    <th class="align-middle text-center" id="th1" style="width:75%">
                        EER: Per selezionare un singolo EER digitare il codice composto da 6 caratteri oppure individuarlo, selezionando, a cascata le famiglie di rifiuti di primo e secondo livello. È possibile selezionare tutti i codici di una famiglia di primo o di secondo livello, selezionando «aggiungi tutti gli EER figli»
                    </th>
                    <th class="align-middle text-center" id="th3"></th>
                </tr>
            </thead>
            <tbody id="tabellaCerHeadBody">
                <tr>
                    <td>
                        <span placement="top">
                            <select [(ngModel)]="CerLivello1Selezionato.idCer" class="h-100 w-100" (change)="onSelezionaCerLivello1()">
                                <option value="-1" selected>Seleziona un EER di livello 1</option>
                                <option [value]="o.idCer" *ngFor="let o of elencoCerLivello1">
                                    {{ (o.codiceCer + '-' + o.descrizioneCer).length  < 100 ? o.codiceCer + '-' + o.descrizioneCer : (o.codiceCer + '-' + o.descrizioneCer).substring(0,100) + '...'}}
                                </option>
                            </select>
                        </span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary btn-sm btn-custom" [disabled]="CerLivello1Selezionato.idCer == -1"
                        (click)="onClickAggiuntiTutti(1)">Aggiungi tutti gli EER figli</button>
                    </td>

                </tr>
                <tr>
                    <td>
                        <span placement="top">
                            <select [(ngModel)]="CerLivello2Selezionato.idCer" class="h-100 w-100" (change)="onSelezionaCerLivello2()" >
                                <option value="-1" selected disabled hidden>Seleziona un EER di livello 2</option>
                                <option [value]="o.idCer" *ngFor="let o of elencoCerLivello2Filtrati" >
                                    {{ (o.codiceCer + '-' + o.descrizioneCer).length  < 200 ? o.codiceCer + '-' + o.descrizioneCer : (o.codiceCer + '-' + o.descrizioneCer).substring(0,200)}}
                                </option>
                            </select>
                        </span>
                    </td>

                    <td>
                        <button type="button" class="btn btn-primary btn-sm btn-custom" [disabled]="CerLivello2Selezionato.idCer == -1"
                        (click)="onClickAggiuntiTutti(2)">Aggiungi tutti gli EER figli </button>
                    </td>



                </tr>

                <tr>
                    <td>
                        <span placement="top" style="display: none;">
                            <select [(ngModel)]="CerLivello3Selezionato.idCer" class="h-100 w-100" (change)="onSelezionaCerLivello3()">
                                <option value="-1" selected disabled hidden></option>
                                <option [value]="o.idCer" *ngFor="let o of elencoCerLivello3Filtrati">
                                    {{o.codiceCer}} - {{o.descrizioneCer}}
                                </option>
                            </select>
                        </span>

                        <span> 
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of elencoCerLivello3FiltratiAutoComplete" [value]="option.codiceCer + ' - ' + option.descrizioneCer"
                                (click)="onClickSuOptionAutoComplete(option.idCer)">
                                  {{option.codiceCer}} - {{option.descrizioneCer}}
                                </mat-option>
                            </mat-autocomplete>
                              <input style="width:100%"
                                        placeholder="Cerca un EER di livello 3"
                                        matInput
                                        [matAutocomplete]="auto"
                                        [formControl]="formControlAutoComplete"
                                        >
                        </span>

                    </td>
                    <td>
                        <button type="button" class="btn btn-primary btn-sm btn-custom" [disabled]="CerLivello3Selezionato.idCer == -1"
                        (click)="onClickAddCerLivello()">Aggiungi EER</button>
                    </td>

                </tr>
            </tbody>
        </table>


        <!-- fine tabella CER-->
        <!--  inizio tabella CER selezionati-->
        <table id="tabellaCer" class="table table-striped table-bordered table-hover table-responsive" style="margin-top:15px; max-height: 300px;" *ngIf="!isLoadingData">
            <thead id="tabellaCerHead" class=" pt-0 mt-0 pb-0 mb-0">
                <tr class="thead-light">
                    <th class="align-middle text-center" id="th10" style="width:100%">EER Selezionati</th>
                    <th class="align-middle text-center" id="th11" style="width: 100px"></th>
                </tr>
            </thead>
            <tbody id="tabellaCerHeadBody">
                <tr *ngFor="let c of elencoCerSelezionati">
                    <td class="align-middle text-center">
                        <span placement="top">
                            {{c.codiceCer}} - {{c.descrizioneCer}}
                        </span>
                    </td>
                    <td class="align-middle text-center">
                        <span ngbTooltip="Elimina riga" placement="left" (click) ="onClickEliminaCerSelezionato(c.idCer)" style="cursor: pointer;">
                            <svg class="icon icon-danger">
                                <use xlink:href="assets/svg/sprite.svg#it-delete"></use>
                            </svg>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>