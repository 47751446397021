export class CER {
    idCer : number;
    idParent :number;
    codiceCer : string;
    descrizioneCer : string;
}

export class ElencoCER{
    CERLivello1 : CER[];
    CERLivello2 : CER[];
    CERLivello3 : CER[];
}