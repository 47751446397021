<table class="table">
    <thead>
      <tr style="background-color: #e6e9f2;">
        <th scope="col">STATO ESERCIZIO</th>
        <th scope="col">UL</th>
        <th scope="col">RAGIONE SOCIALE DITTA</th>
        <th scope="col">INDIRIZZO UL</th>
        <th scope="col">COMUNI INTERESSATI</th>
        <th scope="col">TIPOLOGIE IMPIANTI</th>
        <th scope="col">EER</th>
        <th scope="col">OPERAZIONI</th>
        <th scope="col">GEO</th>
        <th scope="col">INFO</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ris of risultatiRicerca; let index = index" [ngClass]="{'rowColor': index % 2 === 1 }">
        <td>
            <!-- <span
                class="badge" [ngStyle]="{'background-color': getColoreById(ris.idStatoEsercizio)}"
                data-toggle="tooltip" data-placement="bottom" title={{ris.statoEsercizio}}
            >
                {{getSiglaById(ris.idStatoEsercizio)}}
            </span> -->
            {{ris?.statoEsercizio}}
        </td>
        <td>{{ris?.codiceUL}} {{ris?.descrizioneUL ? " - " + ris?.descrizioneUL : ""}}</td>
        <td>{{ris?.ragioneSocialeTitolare}}</td>
        <td> {{ris?.tipoIndirizzoSede}} {{ris?.indirizzoSede}} {{ris?.civicoSede}} {{ris?.frazioneSede}} {{ris?.localitaSede}} {{ris?.capSede}} {{mappaValori.get(ris.idUL)?.primoComune}} </td>
        <td>
            <span *ngFor="let comune of mappaValori.get(ris.idUL)?.listaComuni">
                {{comune}}
            </span>
        </td>
        <td>
            <span *ngFor="let tipoImpianto of mappaValori.get(ris.idUL)?.listaImpianti">
                {{tipoImpianto}}
            </span>
        </td>
        <td>
            <span *ngFor="let cer of mappaValori.get(ris.idUL)?.listaCer">
                {{cer}}
            </span>
        </td>
        <td>
            <span *ngFor="let operazione of mappaValori.get(ris.idUL)?.listaOperazioni">
                {{operazione}}
            </span>
        </td>
        <td>
            <a *ngIf="ris.idGeo" target="_blank" href="{{linkGeo.replace('{0}', ris.idUL)}}">
                <!-- <i class="icon fa fa-globe geoPresente" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i> -->
                <!-- <i class="icon fal fa-arrow-right geoPresente" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i> -->
                <div data-toggle="tooltip" title="Visualizza Georeferenziazione">
                    <svg class="icon icon-primary" title="Visualizza Georeferenziazione">
                        <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-map-marker"></use>
                    </svg>
                </div>
            </a>
            <!-- <i *ngIf="!ris.idGeo" class="icon fa fa-globe geoPresente" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i> -->
            <!-- <i *ngIf="!ris.idGeo" class="icon fal fa-arrow-right" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i> -->
            <div data-toggle="tooltip" title="Visualizza Georeferenziazione" >
                <svg *ngIf="!ris.idGeo" class="icon icon-secondary">
                    <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-map-marker"></use>
                </svg>
            </div>
        </td>
        <td>
            <a (click)="onClickApriDettaglio(ris)">
                <!-- <i class="icon fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Dettaglio" id="iconaInfo"></i>  -->
                <!-- <i class="icon fa fa-map-marker" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Dettaglio" id="iconaInfo"></i>  -->
                <div data-toggle="tooltip" title="Visualizza Dettaglio">
                    <svg class="icon icon-primary" >
                        <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-arrow-right"></use>
                    </svg>
                </div>

            </a>
        </td>
      </tr>
    </tbody>
  </table>