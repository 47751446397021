import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RicercaImpianti } from '../entities/ricerca';
import { CGRService } from '../services/cgrervice.service';

@Component({
    selector: 'app-paginatore-risultati-ricerca',
    templateUrl: './paginatore-risultati-ricerca.component.html',
    styleUrls: ['./paginatore-risultati-ricerca.component.css']
})
export class PaginatoreRisultatiRicercaComponent implements OnInit {

    @Input()
    numeroPagine: number = 0;
    @Input()
    paginaCorrente: number = 1;
    @Input()
    ricerca: RicercaImpianti;
    
    @Output() 
    cambiaPagina : EventEmitter<number> = new EventEmitter();

    @Output()
    effettuaNuovaRicerca : EventEmitter<boolean> = new EventEmitter();

    constructor(private service : CGRService) {}

    ngOnInit() {
    }

    onCambiaPagina(nuovaPagina: number){
        this.cambiaPagina.emit(nuovaPagina);
    }

    onClickTornaRicerca(){
        console.log("click");
        this.effettuaNuovaRicerca.emit(true);
    }

    onClickScaricaReport() {
        this.service.activeLoading();
        this.service.getReport(this.ricerca).then( (data) => {
            let downloadURL = window.URL.createObjectURL(data);
            let link = document.createElement('a');
            link.href = downloadURL;
            let dateArray = new Date().toLocaleDateString('it', { year: 'numeric', month:'2-digit', day:'2-digit'}).split('/');
            let date = dateArray.reverse().join('-');
            link.download = `${date}-risultati-ricerca.xlsx`;
            link.click();
            this.service.stopLoading();
        }); 
    }

}
