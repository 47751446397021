
export interface RisultatoRicerca{
 
    idUL : number;
    codiceUL : string;
    ragioneSocialeTitolare : string;
    tipoIndirizzoSede : string;
    indirizzoSede : string;
    frazioneSede : string;
    localitaSede : string;
    civicoSede : string;
    capSede : string;
    statoEsercizio: string;
    idGeo: number;
    datiAtto: string;
    potRifiutiPericolosiAnno: number;
    potRifiutiNonPericolosiAnno: number;
    potRifiutiTotaliAnno: number;
    potRifiutiPericolosiGiorno: number;
    potRifiutiNonPericolosiGiorno: number;
    potRifiutiTotaliGiorno: number;
    volRifiutiTotPericolosi: number;
    volRifiutiTotNonPericolosi: number;
    volRifiutiTot: number;
    volRifiutiDepositoPericolosi: number;
    volRifiutiDepositoNonPericolosi: number;
    volRifiutiDepositoTot: number;
    volRifiutiRiservaPericolosi: number;
    volRifiutiRiservaNonPericolosi: number;
    volRifiutiRiservaTot: number;
    listaComuni: Comuni[];
    tipologieImpianto : DatiImpiantoRicerca[];
    listaImpianti: Impianto[];
    operazioni : OperazioniRicerca[];
    listaCer : CerRicerca[];
}

export interface Comuni {
    istatComune: string;
    istatProvincia: string;
    nomeComune: string;
    nomeProvincia: string
    siglaProvincia: string;
}

export interface DatiImpiantoRicerca{
    id : number;
    idUL : number;
    idTipoImpianto : number;
    descrTipoImpianto : string;
}

export interface Impianto {
    idImpianto: number;
    idUL: number;
    idTipoImpianto: number;
    tipoImpianto: string;
    discVolAutMq: string;
    autSupAutTotMq: string;
    stocInTotMq: string;
    potTAnno: string;
    listaTipologie: Tipologia[];
}

export interface Tipologia {
    elenco: string;
    tipologia: string;
    scheda: string;
    descrizioneTipologia: string;
    totAnno: number;
}

export interface OperazioniRicerca{
    id : number;
    idUL : number;
    idOperazione : number;
    codiceOperazione : string;
    descrOperazione : string;
}

export interface CerRicerca{
    id : number;
    idUL : number;
    idCer : number;
    codiceCer : string;
    descrCer : string;
}

export class ListaValori {
    primoComune: string;
    listaComuni: string[];
    listaImpianti: string[];
    listaCer: string[];
    listaOperazioni: string[]
}