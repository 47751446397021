<main class="col">

  <h2>Viewer Catasto Georeferenziato impianti Rifiuti</h2>
  <p>
    Nel viewer del C.G.R. Web è possibile visualizzare ed interrogare gli impianti di trattamento di rifiuti censiti dalle Province, enti competenti al rilascio delle autorizzazioni. I dati sono aggiornati in automatico dal C.G.R. gestionale ogni giorno.
  </p>
  <div class="divButton">
    <button class="btn btn-primary" (click)="apriLink('VIEWER')">VAI AL VIEWER</button>
  </div>
  
  <h2> DOWNLOAD DATI CARTOGRAFICI </h2>
  <p>
    Il servizio di download reso disponibile dal Geoportale di Regione Lombardia consente di scaricare in formato shapefile gli impianti di trattamento di rifiuti, sia puntuali che poligonali ove presenti, con associati alcuni dati alfanumerici. I dati sono aggiornati in automatico dal C.G.R. gestionale ogni settimana.  
  </p>
  <div class="divButton">
    <button class="btn btn-primary" (click)="apriLink('DOWNLOAD')">VAI AL DOWNLOAD</button>
  </div>

</main>