<!--
    Da tenere in considerazione che nel be la prima pagina parte da 0,
    mentre qui da 1.  Il componente riceve in ingresso la pagina corrente + 1
    Quindi, ad esempio spostarsi indietro di una pagina da qui, equivale a passare la pagina corrente - 2
    Spostarsi in avanti equivale alla corrente
-->

<nav class="pagination-wrapper" aria-label="Esempio di navigazione con ellissi/more" *ngIf="numeroPagine != 0">
    <ul class="pagination">
        <li class="page-item" *ngIf="paginaCorrente > 1">
        <a class=" page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente-2)">
            <svg class="icon icon-primary">
                <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-chevron-left"></use>
            </svg>
            <span class="sr-only">Pagina precedente</span>
        </a>
        </li>
        <li class="page-item" *ngIf="numeroPagine > 4 && paginaCorrente != 1">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(0)">1</a>
        </li>
        <li class="page-item" *ngIf="numeroPagine > 4 && paginaCorrente != 1">
            <span class="page-link">...</span>
        </li>
        <li class="page-item" *ngIf="paginaCorrente - 2 > 1">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente-3)">{{paginaCorrente - 2}}</a>
        </li>
        <li class="page-item" *ngIf="paginaCorrente - 1 > 0">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente-2)">{{paginaCorrente - 1 }}</a>
        </li>
        <li class="page-item active">
            <a class="page-link" href="#ricerca" aria-current="page">
                <span class="d-inline-block d-sm-none">Pagina </span>{{paginaCorrente}}
            </a>
        </li>
        <li class="page-item" *ngIf="paginaCorrente + 1 < numeroPagine">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente)">{{paginaCorrente + 1}}</a>
        </li>
        <li class="page-item" *ngIf="paginaCorrente + 2 < numeroPagine">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente+1)">{{paginaCorrente + 2}}</a>
        </li>
        <li class="page-item" *ngIf="paginaCorrente + 3 < numeroPagine">
            <span class="page-link" (click)="onCambiaPagina(paginaCorrente+2)">...</span>
        </li>
        <li class="page-item" *ngIf="paginaCorrente != numeroPagine">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(numeroPagine-1)">{{numeroPagine}}</a>
        </li>
        <li class="page-item" *ngIf="paginaCorrente != numeroPagine">
            <a class="page-link" href="#ricerca" (click)="onCambiaPagina(paginaCorrente)">
                <span class="sr-only">Pagina successiva</span>
                <svg class="icon icon-primary">
                    <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-chevron-right"></use>
                </svg>
            </a>
        </li>
    </ul>
</nav>

<div *ngIf="numeroPagine == 0">
    Nesun risultato trovato
</div>

<div class="button-div">
    <button class="btn btn-primary"  (click)="onClickScaricaReport()">
        <svg class="icon icon-white"><use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-download"></use></svg>
        Scarica report
    </button>
</div>