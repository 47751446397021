<footer class="footer">
    <div class="container-fluid contain">
      <div class="row">
        <div class="col-12 my-4 font-weight-bold">
            C.G.R. Web - Versione pubblica del Catasto Georeferenziato Impianti Rifiuti
        </div>
      </div>
      <div class="row">
        <div class="col link-list-wrapper">
            <ul class="link-list">
                <li class="list-item"><a data-toggle="modal" href="#modal-privacy">Privacy</a></li>
                <li class="list-item"><a data-toggle="modal" href="#modal-accessibilita">Accessibilità</a></li>
                <li class="list-item"><a data-toggle="modal" href="#modal-cookie">Cookie Policy</a></li>
            </ul>
        </div>
        <div class="col link-list-wrapper">
            <ul class="link-list">
              <li class="list-item"><a data-toggle="modal" href="#modal-note">Note Legali</a></li>
              <li class="list-item"><a data-toggle="modal" href="#modal-contatti">Contatti e Assistenza</a></li>
            </ul>
        </div>
        <div class="col link-list-wrapper">
            <ul class="link-list">
              <li class="list-item"><a href="https://www.cgr.servizirl.it/" target="_blank">C.G.R (Accesso riservato alle Autorità Competenti)</a></li>
            </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-3">
          <span class="d-inline-block">&copy; Copyright Regione Lombardia</span>
          <span class="d-inline-block"> - tutti i diritti riservati</span>
          <span class="d-inline-block"> - C.F. 80050050154</span>
          <span class="d-inline-block"> - Piazza Città di Lombardia 1</span>
          <span class="d-inline-block"> - 20124 Milano</span>
        </div>
      </div>
    </div>
</footer>

<div id="modal-privacy" class="modal it-dialog-scrollable" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="callout note">
                    <div class="callout-title">Privacy</div>
                    <span>
                        In armonia con quanto previsto dal Regolamento Europeo sulla protezione dei dati personali 2016/679, dal D.lgs. 30
                        giugno 2003, n. 196 c.d. Codice Privacy e dal D.lgs. 10 agosto 2018, n. 101, il cui obiettivo è proteggere i
                        diritti e le libertà fondamentali delle persone fisiche, in particolare il diritto alla protezione dei dati
                        personali, informiamo sulle modalità di trattamento dei dati personali trattati nell’ambito del servizio CGR –
                        Catasto Georeferenziato Impianti Rifiuti, sui relativi diritti e su come questi possono essere esercitati. Tali
                        modalità non riguardano altri siti web esterni eventualmente collegati tramite link al sito.
                    </span>
                    <ol>
                        <li>
                            <h6><strong>Finalità del trattamento dei dati personali</strong></h6>
                            I dati personali verranno raccolti e trattati nel rispetto dei principi di correttezza, liceità, trasparenza e
                            tutela della riservatezza, per espletare le finalità del servizio CGR, predisposto da Regione Lombardia per
                            acquisire le informazioni relative alle autorizzazioni agli impianti di trattamento dei rifiuti rilasciate dagli
                            enti competenti.
                        </li>
                        <li>
                            <h6><strong>Modalità del trattamento dei dati</strong></h6>
                            I dati saranno trattati con l’ausilio di mezzi elettronici o comunque automatizzati e trasmessi attraverso reti
                            telematiche. Il titolare adotta misure tecniche e organizzative adeguate a garantire un livello di sicurezza
                            idoneo rispetto alla tipologia di dati trattati.
                        </li>
                        <li>
                            <h6><strong>Titolare del Trattamento</strong></h6>
                            Il Titolare del trattamento è Regione Lombardia, con sede in Piazza Città di Lombardia,1 - 20124 Milano.
                        </li>
                        <li>
                            <h6><strong>Responsabile della Protezione dei dati (RPD)</strong></h6>
                            Il Responsabile della Protezione dei dati (RPD) è contattabile al seguente indirizzo mail:
                            <a href="mailto:rpd@regione.lombardia.it">
                            rpd@regione.lombardia.it.</a>
                        </li>
                        <li>
                            <h6><strong>Destinatari e ambito di comunicazione dei dati</strong></h6>
                            I dati raccolti sono trattati esclusivamente dal personale e da collaboratori dell'Ente Regione Lombardia. 
                            I dati inoltre, potranno essere comunicati a soggetti terzi (es. ARIA S.p.A.), in qualità di Responsabili del Trattamento, nominati dal Titolare. L’elenco di detti soggetti terzi è disponibile presso la sede Titolare. I destinatari dei dati sono stati adeguatamente istruiti per poter trattare i dati personali e assicurano il medesimo livello di sicurezza offerto dal Titolare.        
                        </li>
                        <li>
                            <h6><strong>Trasferimento dei dati personali verso Paesi non appartenenti all’Unione Europea</strong></h6>
                            I dati raccolti ed elaborati non saranno in alcun modo oggetto di trasferimento in un Paese terzo extra europeo.
                        </li>
                        <li>
                            <h6><strong>Tempi di conservazione dei dati</strong></h6>
                            I dati personali forniti dall’utente vengono conservati per il periodo di tempo necessario a permettere la fruizione del servizio da parte dell’utente stesso. <br>
                            I dati saranno tenuti a disposizione per il tempo sufficiente a svolgere tutte le attività di controllo previste dalla normativa in materia.
                            
                        </li>
                        <li>
                            <h6>Diritti dell'interessato</h6>
                            Gli interessati possono esercitare i diritti di cui agli artt. 15 e ss. del Regolamento UE 679/2016, ove applicabili; fra questi, il diritto di chiedere la rettifica o la cancellazione dei dati di registrazione, la limitazione del trattamento o di opporsi al trattamento, nei casi previsti.
                            Le richieste per l’esercizio di questi diritti dovranno essere inviate all’indirizzo di posta elettronica <a href="mailto:ambiente_clima@pec.regione.lombardia.it">ambiente_clima@pec.regione.lombardia.it</a> all’attenzione del Direttore della Direzione Generale Ambiente e Clima, oppure a mezzo posta raccomandata all’indirizzo Regione Lombardia, Piazza Città di Lombardia 1, 20124 Milano all'attenzione del Direttore della Direzione Generale Ambiente e Clima.
                            È sempre possibile, inoltre, esercitare il diritto di proporre reclamo ad un’Autorità di Controllo.
                        </li>
                    </ol>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-accessibilita" class="modal it-dialog-scrollable" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="callout note">
                    <div class="callout-title">Accessibilità</div>
                    <span>
                        Il C.G.R. Web - Catasto Georeferenziato Impianti Rifiuti è stato sviluppato con l’obiettivo di offrire uno strumento completo,
                        semplice, comprensibile e fruibile in ogni condizione.<br>
                        Le caratteristiche principali che per raggiungere l’obiettivo devono essere garantite sono <b>l’accessibilità</b>,
                        ossia la possibilità di accedere al sito indipendentemente da eventuali limitazioni tecnologiche, fisiche o
                        ambientali, la responsività, ossia la capacità del sito di adattarsi in modo automatico alla grandezza del
                        dispositivo utilizzato dall’utente (computer, tablet o smartphone), risultando facile da leggere e da navigare, e
                        l’usabilità, ossia la facilità con cui gli utenti riescono a trovare le informazioni che cercano ed utilizzare le
                        funzioni del sistema in modo semplice ed intuitivo.<br>
                        Il C.G.R. Web garantisce il rispetto dei <b>requisiti tecnici previsti nell’allegato A della </b>
                        <a href="https://www.agid.gov.it/dm-8-luglio-2005-allegato-A"> Legge 4/2004 </a>
                        "Disposizioni per favorire l'accesso dei soggetti disabili agli strumenti informatici" (cosiddetta Legge Stanca), ed è stato sviluppato con il
                        linguaggio di marcatura HTML5 e fogli di stile CSS.<br>
                        La progettazione è avvenuta in diverse fasi, ciascuna con momenti differenti di verifica della qualità, sia sotto
                        l’aspetto dell’usabilità che dell’accessibilità. Ogni modello di pagina è quindi stato sottoposto a verifiche
                        attraverso l’utilizzo di Strumenti automatici di validazione<br>
                        e successivamente attraverso <b>verifiche manuali.</b> <ul></ul>
                        <b>Strumenti automatici di validazione</b><br>
                        Tutte le pagine del portale sono state verificate con i seguenti strumenti automatici:<br>
                    </span>
                    <ul>
                        <li>Validazione HTML tramite il W3C Markup Validation Service</li>
                        <li>Validazione CSS tramite il W3C CSS Validation Service</li>
                        <li>Supporto automatico alla verifica di accessibilità tramite Validatore.it</li>
                    </ul>
                    <span>
                        <b>Compatibilità sistemi operativi e browser</b><br>
                        Il sistema è stato progettato e testato per garantire la massima compatibilità con i principali sistemi operativi
                        e browser, sia dal punto di vista funzionale che di visualizzazione. Il sito è stato verificato quindi con:<br><br>
                        <b>Sistemi operativi</b><br>
                    </span>
                    <ul>
                        <li>Mac Os X 10.11.15</li>
                        <li>Windows 7-10</li>
                        <li>iOS 9.3.2</li>
                    </ul>
                    <b> Browser</b><br>
                    <ul>
                        <li>Edge (Latest)</li>
                        <li>Chrome 58 (su PC)</li>
                        <li>Firefox 45 (su PC, Mac e Android)</li>
                        <li>Safari 9.11 (su Mac e iPad)</li>
                    </ul>
					<span>
                        <b>Feedback e recapiti</b><br>
                        Per segnalazioni, suggerimenti o commenti sui temi dell'accessibilità e usabilità del C.G.R. Web è possibile scrivere all'indirizzo <b><a href="mailto:osservazioni.CGR@regione.lombardia.it">osservazioni.CGR@regione.lombardia.it</a>.</b>
                    </span>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-cookie" class="modal it-dialog-scrollable" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="callout note">
                    <div class="callout-title">Cookie Policy</div>
                    <b>Cosa sono i cookie?</b><br>
                    <span>
                        I cookie sono piccoli file di testo che vengono inviati dal sito web visitato dall’utente sul suo dispositivo (solitamente al browser),
                        dove vengono memorizzati in modo da poterlo riconoscere alla successiva visita. Ad ogni visita successiva, infatti, i cookie sono reinviati dal dispositivo
                        dell’utente al sito.  I cookie possono essere installati non solo dallo stesso gestore del sito visitato
                        dall’utente (i cosiddetti cookie di prima parte), ma anche da un sito diverso che installa cookie per il tramite del primo sito (cookie di terze parti)
                        ed è in grado di riconoscerli. Questo accade perché sul sito visitato possono essere presenti elementi (immagini, mappe, suoni, link a pagine web di altri
                        domini, etc) che risiedono su server diversi da quello del sito visitato. Ai sensi di quanto indicato nel Provvedimento del Garante della Privacy del 8 Maggio
                        2014, “Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie” (di seguito “Provvedimento”), per i
                        cookie installati da terze parti, gli obblighi di informativa e, se ne ricorrono i presupposti, di consenso, gravano sulle terze parti;
                        il titolare del sito, in qualità di intermediario tecnico fra le terze parti e gli utenti, è tenuto ad inserire nell’informativa estesa i link aggiornati
                        alle informative e i moduli di consenso delle terze parti. <br><br>
                        In base alla finalità, i cookie si distinguono in <strong>cookie di profilazione</strong> ed in <strong>cookie tecnici</strong>. In base alla loro durata,
                        i cookie si distinguono in <u>persistenti</u>, che rimangono memorizzati, fino alla loro scadenza, sul dispositivo dell’utente, salvo rimozione da parte di
                        quest’ultimo, e di <u>sessione</u>, che non vengono memorizzati in modo persistente sul dispositivo dell’utente e svaniscono con la chiusura del browser.<br><br>
                        <strong>I cookie di profilazione</strong> servono a tracciare la navigazione dell’utente, analizzare il suo comportamento ai fini di marketing e creare
                        profili sui suoi gusti, abitudini, scelte, etc. in modo da trasmettere messaggi pubblicitari mirati in relazione agli interessi dell’utente ed in linea con
                        le preferenze da questo manifestati nella navigazione online. Tali cookie possono essere installati sul dispositivo dell’utente solo se questo abbia espresso
                        il proprio consenso con le modalità indicate nel Provvedimento. <br><br>
                        <strong>I cookie tecnici</strong> sono installati al solo fine di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica,
                        o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a
                        erogare tale servizio" (cfr. art. 122, comma 1, del D.Lgs. 196/2003 - cd. Codice Privacy - così come modificato dal d.lgs. 69/2012).
                        Sono solitamente utilizzati per consentire una navigazione efficiente tra le pagine, memorizzare le preferenze degli utenti, memorizzare informazioni su
                        specifiche configurazioni degli utenti, effettuare l’autenticazione degli utenti, etc. I cookie tecnici possono essere suddivisi in cookie di navigazione,
                        utilizzati al fine di registrare dati utili alla normale navigazione e fruizione del sito web sul dispositivo dell'utente
                        (permettendo, ad esempio, di ricordare la dimensione preferita della pagina in un elenco) e cookie funzionali, che consentono al sito web di ricordare le
                        scelte effettuate dall’utente al fine di ottimizzarne le funzionalità (ad esempio, ricordare le impostazioni specifiche di un utente, come la selezione del
                        paese e, se impostato, lo stato di accesso permanente). Alcuni di questi cookie (detti essenziali o strictly necessary) abilitano funzioni senza le quali non
                        sarebbe possibile effettuare alcune operazioni.<strong> Ai sensi del suddetto art. 122, comma 1 del Codice Privacy, l’utilizzo dei cookie tecnici non richiede
                        il consenso degli utenti.</strong>
                        Ai cookie tecnici sono assimilati (e, pertanto, come precisato nel Provvedimento, per la relativa installazione non è richiesto il consenso degli utenti,
                        né gli ulteriori adempimenti normativi) i <strong>cookie analytics</strong>, se realizzati e utilizzati direttamente dal gestore del sito prima parte
                        (senza, dunque, l'intervento di soggetti terzi), ai fini di ottimizzazione dello stesso per raccogliere informazioni aggregate sul numero degli utenti e su
                        come questi visitano il sito stesso, nonché quelli realizzati e messi a disposizione da terze parti ed utilizzati dal sito prima parte per meri fini
                        statistici, qualora vengano, fra l’altro, adottati strumenti idonei a ridurre il potere identificativo dei cookie analytics che utilizzano
                        (ad esempio, mediante il mascheramento di porzioni significative dell'indirizzo IP).<br><br>
                        <b>Quali cookie sono utilizzati in C.G.R. Web – Catasto Georeferenziato impianti Rifiuti?</b><br>
                        Il sito CGR non utilizza cookie. <br><br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-note" class="modal it-dialog-scrollable" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="callout note">
                    <div class="callout-title">Note Legali</div>
                    <span>
                        <b> Note Legali</b><br>
                        Il link a questo sito è sottoposto alla preventiva autorizzazione di Regione Lombardia che autorizza il link alle condizioni di seguito indicate.
                        Il soggetto che intende inserire il link deve darne preventiva comunicazione a Regione Lombardia inviando un e-mail all’indirizzo
                        <a href="mailto:osservazioni.CGR@regione.lombardia.it">osservazioni.CGR@regione.lombardia.it</a>, indicando i propri dati identificativi. Al medesimo
                        indirizzo andranno comunicate tutte le variazioni riguardanti la pubblicazione del link (eliminazione, modifica o altro).
                        L'utilizzo del link diretto al servizio <a href="https://www.cgr.servizirl.it">https://www.cgr.servizirl.it</a> è autorizzato alle seguenti
                        condizioni:
                        <ul>
                            <li>
                                il link non deve determinare nell’utente confusione circa la titolarità dei contenuti del sito, le attività del soggetto che effettua il link e
                                quelle offerte dal servizio
                            </li>
                            <li>il link deve essere effettuato in modo tale che il collegamento avvenga in modo diretto alla prima pagina.</li>
                        </ul>
                        Sono in ogni caso vietate le pratiche di deep linking, inline linking e framing. Regione Lombardia si riserva di revocare la presente autorizzazione in
                        qualsiasi momento. In ogni caso la realizzazione di un link al sito <a href="https://www.cgr.servizirl.it/">https://www.cgr.servizirl.it/</a> costituisce
                        integrale accettazione delle presenti regole. <br><br>
                        <b>Copyright</b><br>
                        Tutte le informazioni, i contenuti (quali, a titolo esemplificativo, testi, immagini, eventuali contenuti audiovisivi, marchi o altri segni distintivi) ed
                        i servizi veicolati, ed eventualmente resi disponibili attraverso il servizio “CGR" sono e restano di titolarità esclusiva dei rispettivi autori e/o detentori
                        dei diritti e, ove ne ricorrano i presupposti, sono tutelati dalle vigenti normative a protezione del diritto d'autore (L. 633/41) e a tutela della proprietà
                        industriale (D. Lgs. 30/2005). Ove non diversamente ed espressamente indicato, e salve le ipotesi nelle quali l'esercizio del diritto d'uso (o di altro diritto
                        di sfruttamento dell'opera o del contenuto) è inderogabilmente concesso ai sensi di legge, qualsiasi utilizzo delle informazioni, dei contenuti e dei servizi
                        veicolati attraverso il servizio “CGR" è soggetto a preventiva autorizzazione da parte del titolare dei diritti. <br><br>
                        <b> Utilizzo del sito</b><br>
                        L'ente Regione Lombardia e, con esso, la società ARIA S.p.A., si adoperano costantemente per mantenere aggiornato il servizio “C.G.R. Web” e per garantire che le
                        informazioni e i contenuti ivi veicolati siano sempre corretti e accurati. Ciò nonostante non è possibile escludere, in senso assoluto, che, anche per
                        ragioni di ordine tecnico, talune informazioni possano non essere completamente esaurienti o aggiornate, e che taluni servizi possano comportare errori,
                        difetti di funzionamento o di compatibilità con particolari dispositivi o ambienti informatici. L'ente Regione Lombardia e ARIA S.p.A. non prestano alcuna
                        specifica garanzia, espressa o implicita, quali, a titolo meramente esemplificativo, garanzie di assenza di errori e/o difetti di funzionamento,
                        garanzie di compatibilità informatica o di idoneità a particolari scopi o usi, relativamente alle informazioni, ai contenuti, e ai servizi veicolati attraverso
                        il servizio “C.G.R. Web”. L'utente prende atto e accetta che l'ente Regione Lombardia e ARIA S.p.A., fatto salvo il caso di dolo o colpa grave, non saranno mai
                        responsabili degli eventuali danni, diretti o indiretti, di qualsiasi natura, e senza esclusione alcuna, ivi compresi i danni per l'eventuale perdita di dati o
                        per l'interruzione del servizio, derivanti all'utente, o a terzi, dall'utilizzo, o dal mancato utilizzo, delle informazioni, dei contenuti e dei servizi resi
                        disponibili attraverso il servizio “C.G.R. Web”. <br><br>
                        <b>Accesso a siti esterni collegati</b><br>
                        Regione Lombardia e la società ARIA SpA non hanno alcuna responsabilità per quanto concerne i siti ai quali è possibile accedere tramite i collegamenti posti
                        all’interno del sito stesso, né sono responsabili delle informazioni ottenute dall’utente tramite l’accesso ai siti raggiungibili tramite collegamento
                        ipertestuale. Sono da ritenersi responsabili della completezza e informazioni i soggetti titolari dei siti ai quali si accede alle condizioni dagli stessi
                        previste. <br><br>
                        <b>Download</b><br>
                        Ogni oggetto presente su questo sito per lo scaricamento (download) come ad esempio la documentazione, salvo diversa indicazione, è liberamente e gratuitamente
                        disponibile alle condizioni stabilite dal titolare. <br><br>
                        <b> Virus informatici</b><br>
                        Regione Lombardia non garantisce che il sito sia privo di errori o virus. La Regione ed i suoi fornitori non sono responsabili per danni subiti dall’utente a causa
                        di tali agenti distruttivi.<br><br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modal-contatti" class="modal it-dialog-scrollable" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content small">
            <div class="modal-body">
                <div class="callout note">
                    <div class="callout-title">Contatti e Assistenza</div>
                    <span>
                        Per segnalazioni di eventuali errori/mancanze nei dati riportati nel C.G.R. è possibile inviare una e-mail al seguente indirizzo di posta elettronica:
                        <b><a href="mailto:osservazioni.CGR@regione.lombardia.it">osservazioni.CGR@regione.lombardia.it</a>.</b> Si prega di indicare nell'oggetto della mail le
                        seguenti informazioni: indirizzo completo, tipologia e ragione sociale dell'impianto.<br><br>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>