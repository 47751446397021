<header style="position: relative;overflow-x: auto;" class="it25-sidebar">

  <div class="it-header-slim-wrapper it25-top-bar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="it-header-slim-wrapper-content">
            <a class="navbar-brand" href="#">
              <img src="bootstrap-lombardia/dist/assets/img/logo-rl-bianco.png" alt="logo regione lombardia"
                class="d-md-none">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="it-header-center-wrapper it25-barra-ist">
    <div style="width: 100%;" class="container-fluid">
      <div class="row">
        <div class="it-header-center-content-wrapper">
          <div class="it-brand-wrapper">
            <app-menu  [menuItems]="menuItems" (pageRequested)="onClickPagina($event)" style="padding: 0 6px;"></app-menu>
            <div class="it25-hamburger-btn-wrapper">
            </div>
            <a href="{{ sitoRegione }}" style="padding-left: 18px;">
              <img src="bootstrap-lombardia/dist/assets/img/logo-rl.png" alt="logo regione lombardia"
                class="it25-header-logo d-none d-md-block">

              <div class="it-brand-text">
                C.G.R. Web – Catasto Georeferenziato Impianti Rifiuti
              </div>
            </a>
          </div> <!-- end it-brand-wrapper -->
        </div>
      </div>
    </div>
  </div>

</header>