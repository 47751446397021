import { MenuItems } from "../entities/menuItems.interface";
import { ConfigurazioneMapping } from "./config.enum";

export const menuItemsConfig: MenuItems = [
    {
        label: 'Home',
        link: 'HOME',
        active: true,
        href: 'javascript:void(0);',
        subMenu: null,
    },
    {
        label: 'Catasto Georeferenziato impianti rifiuti',
        link: null,
        active: true,
        href: null,
        dataTarget: "collapse-sc1",
        subMenu: [
            {
                label: 'Ricerca Impianti',
                link: 'RICERCA',
                active: true,
                href: 'javascript:void(0);'
            },
            {
                label: 'Dati Cartografici',
                link: 'DATI_CARTOGRAFICI',
                active: true,
                href: 'javascript:void(0);',
            }
        ],
    },
    {
        label: 'Criteri Localizzativi',
        link: 'CRITERI_LOCALIZZATIVI',
        active: true,
        href: null,
        subMenu: null,
    },
    {
        label: 'Viewer criteri localizzativi',
        link: `${ConfigurazioneMapping.linkCriloc}`,
        active: true,
        href: null,
        target: '_blank',
        subMenu: null,
    }
]