import { Injectable } from '@angular/core';
import { ElencoCER } from '../entities/cer';
import { Comune } from '../entities/comune';
import { Operazione } from '../entities/operazione';
import { Provincia } from '../entities/province';
import { StatoEsercizio } from '../entities/stato-esercizio';
import { TipoImpianto } from '../entities/tipo-impianto';
import { CGRService } from './cgrervice.service';

/*
RB: Carica ogni dato necessario alla ricerca degli impianti. Ad eccezione dei cer.
I cer sono gestiti singolarmente e al momento dentro al loro componenet
*/

@Injectable({
  providedIn: 'root'
})
export class DatiRicercaService {

    datiCaricati : boolean = false;
    provinceLombardia : Provincia[] = [];
    comuniLombardia : Comune[] = [];
    tipiImpianto : TipoImpianto[] = [];
    operazioni : Operazione[] = [];
    statiEsercizio : StatoEsercizio[] = [];


  constructor(private service : CGRService) { }

  async caricaDati(){
    Promise.all([
        this.caricaStatoEsercizio(),
        this.caricaTipiImpianto(),
        this.caricaOperazioni(),
        this.caricaProvinceEComuni()
    ]).then(x =>{
        this.datiCaricati = true;
    });    
  }

  async caricaProvinceEComuni(){
    this.provinceLombardia = await this.service.getProvinceLombardia();
    let comuniPromise : Promise<Comune[]>[] = [];

    for(let i = 0; i < this.provinceLombardia.length; i++){
        comuniPromise.push( this.service.getComuniByIstat(this.provinceLombardia[i].istatProvincia));
    };

    Promise.all(comuniPromise).then(listaComuniPerProvincia=>{
        for(let i=0;i<listaComuniPerProvincia.length;i++){
            for(let j=0;j<listaComuniPerProvincia[i].length;j++)
                this.comuniLombardia.push(listaComuniPerProvincia[i][j]);
        };
    });
  }

  async caricaTipiImpianto(){
    this.tipiImpianto = await this.service.getTipiImpianto(); 
  }

  async caricaOperazioni(){
    this.operazioni = await this.service.getOperazioni();
  }


  async caricaStatoEsercizio(){
    this.statiEsercizio = await this.service.getStatiEsercizio();
  }




  async getProvinceLombardia() : Promise<Provincia[]>{
    return this.provinceLombardia;
  }

  async getComuniLombardia() : Promise<Comune[]>{
    return this.comuniLombardia;
  }

  async getTipiImpianto() : Promise<TipoImpianto[]>{
    return this.tipiImpianto;
  }

  async getOperazioni() : Promise<Operazione[]>{
    return this.operazioni;
  }

  async getStatiEsercizio() : Promise<StatoEsercizio[]>{
    return this.statiEsercizio;
  }
}
