import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';
import { Configurazione } from '../../entities/configurazione';
import { ConfigurazioneMapping } from '../../config/config.enum';
import * as bootstrap from 'bootstrap';
import { MenuItem, MenuItems } from '../../entities/menuItems.interface';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  @Output() pageRequested: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();
  @Input() menuItems: MenuItems;

  offcanvasElementList = [].slice.call(document.querySelectorAll('#offcanvas'))
  offcanvasList = this.offcanvasElementList.map(function (offcanvasEl) {
    return new bootstrap.Offcanvas(offcanvasEl)
  });

  onClickPagina(value: MenuItem) {
    this.pageRequested.emit(value);
  }


}
