<h2>DETTAGLIO</h2>

<div class="callout note">
    <div class="callout-title">DATI IDENTIFICATIVI</div>
    <table>
        <tr>
            <th> CODICE </th>
            <td> {{risultato.codiceUL}} </td>
        </tr>
        <tr>
            <th> RAGIONE SOCIALE </th>
            <td> {{risultato.ragioneSocialeTitolare}} </td>
        </tr>
        <tr>
            <th> INDIRIZZO </th>
            <td>
                {{risultato.tipoIndirizzoSede}} {{risultato.indirizzoSede}} {{risultato.civicoSede}} {{risultato.frazioneSede}} {{risultato.localitaSede}} {{risultato.capSede}}
                {{comune}} {{provincia}}
                <a *ngIf="risultato.idGeo" target="_blank" href="{{linkGeo.replace('{0}', risultato.idUL.toString())}}">
                    <!-- <i class="icon fa fa-globe geoPresente" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i> -->
                    <svg class="icon icon-primary" title="Visualizza Georeferenziazione">
                        <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-map-marker"></use>
                    </svg>
                </a>
                <i *ngIf="!risultato.idGeo" class="icon fa fa-globe" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualizza Georeferenziazione"></i>
            </td>
        </tr>
        <tr>
            <th> STATO ESERCIZIO </th>
            <td> {{risultato.statoEsercizio}} </td>
        </tr>
        <tr>
            <th> DATI ATTO DI AUTORIZZAZIONE </th>
            <td> {{risultato.datiAtto}} </td>
        </tr>
        <tr>
            <th> COMUNI INTERESSATI </th>
            <td> {{comuniIntaressati}} </td>
        </tr>
        <tr>
            <th> TIPOLOGIE IMPIANTI </th>
            <td> {{tipologieImpiantiDesc}} </td>
        </tr>
    </table>
</div>

<div class="callout note">
    <div class="callout-title"> POTENZIALITÀ </div>
    <table>
        <thead>
            <tr>
              <th> RIFIUTI </th>
              <th> TONNELLATE/GIORNO </th>
              <th> TONNELLATE/ANNO </th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td> Pericolosi </td>
                <td> {{risultato.potRifiutiPericolosiGiorno ? this.numberFormat.format(risultato.potRifiutiPericolosiGiorno) : '0,00'}} </td>
                <td> {{risultato.potRifiutiPericolosiAnno ? this.numberFormat.format(risultato.potRifiutiPericolosiAnno) : '0,00'}} </td>
            </tr>
            <tr>
                <td> Non Pericolosi </td>
                <td> {{risultato.potRifiutiNonPericolosiGiorno ? this.numberFormat.format(risultato.potRifiutiNonPericolosiGiorno) : '0,00'}} </td>
                <td> {{risultato.potRifiutiNonPericolosiAnno ? this.numberFormat.format(risultato.potRifiutiNonPericolosiAnno) : '0,00'}} </td>
            </tr>
            <tr>
                <td> Totali </td>
                <td> {{risultato.potRifiutiTotaliGiorno ? this.numberFormat.format(risultato.potRifiutiTotaliGiorno) : '0,00'}} </td>
                <td> {{risultato.potRifiutiTotaliAnno ? this.numberFormat.format(risultato.potRifiutiTotaliAnno) : '0,00'}} </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="callout note">
    <div class="callout-title"> VOLUMETRIA MASSIMA DELLO STOCCAGGIO </div>
    <table>
        <thead>
            <tr>
                <th> RIFIUTI </th>
                <th> TOTALE (MC) </th>
                <th> DEPOSITO PRELIMINARE (MC) </th>
                <th> MESSA IN RISERVA (MC) </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td> Pericolosi </td>
                <td> {{risultato.volRifiutiTotPericolosi ? this.numberFormat.format(risultato.volRifiutiTotPericolosi) : '0,00'}} </td>
                <td> {{risultato.volRifiutiDepositoPericolosi ? this.numberFormat.format(risultato.volRifiutiDepositoPericolosi) : '0,00'}} </td>
                <td> {{risultato.volRifiutiRiservaPericolosi ? this.numberFormat.format(risultato.volRifiutiRiservaPericolosi) : '0,00'}} </td>
            </tr>
            <tr>
                <td> Non Pericolosi </td>
                <td> {{risultato.volRifiutiTotNonPericolosi ? this.numberFormat.format(risultato.volRifiutiTotNonPericolosi) : '0,00'}} </td>
                <td> {{risultato.volRifiutiDepositoNonPericolosi ? this.numberFormat.format(risultato.volRifiutiDepositoNonPericolosi) : '0,00'}} </td>
                <td> {{risultato.volRifiutiRiservaNonPericolosi ? this.numberFormat.format(risultato.volRifiutiRiservaNonPericolosi) : '0,00'}} </td>
            </tr>
            <tr>
                <td> Totali </td>
                <td> {{risultato.volRifiutiTot ? this.numberFormat.format(risultato.volRifiutiTot) : '0,00'}} </td>
                <td> {{risultato.volRifiutiDepositoTot ? this.numberFormat.format(risultato.volRifiutiDepositoTot) : '0,00'}} </td>
                <td> {{risultato.volRifiutiRiservaTot ? this.numberFormat.format(risultato.volRifiutiRiservaTot) : '0,00'}} </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="callout note">
    <div class="callout-title">DATI IMPIANTI</div>
    <table *ngIf="tipologieImpiantiDesc.includes('comunicazione')">
        <thead>
            <tr>
                <th> SCHEDE IMPIANTO COMUNICAZIONE </th>
                <th> RECUPERO TOTALE (T/ANNO) </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let imp of risultato.listaImpianti">
                <tr *ngFor="let tip of imp.listaTipologie">
                    <td>
                        <b>Elenco: </b> {{tip.elenco}} <b>- Tipologia: </b> {{tip.tipologia}} <b>- Scheda: </b> {{tip.scheda}} <b>- Descrizione: </b>
                        <br/>
                        {{tip.descrizioneTipologia}}
                    </td>
                    <td> {{tip.totAnno ? this.numberFormat.format(tip.totAnno) : '0,00'}} </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <table *ngIf="'comunicazione' !== tipologieImpiantiDesc">
        <thead>
            <tr>
                <th> IMPIANTO </th>
                <th> DATI IMPIANTO </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let imp of risultato.listaImpianti">
                <tr *ngIf="imp.idTipoImpianto !== 13"> <!-- COMUNICAZIONE -->
                    <td> {{imp.tipoImpianto}} </td>
                    <td>
                        <ng-container [ngSwitch]="imp.idTipoImpianto">
                            <ng-container *ngSwitchCase="3"> <!-- DISCARICA -->
                                <b> Volume autorizzato (mc): </b> 
                                {{imp.discVolAutMq ? imp.discVolAutMq : '0,00' }}
                            </ng-container>
                            <ng-container *ngSwitchCase="5"> <!-- AUTODEMOLIZIONE -->
                                <b> Superficie autorizzata totale (mq): </b> 
                                {{imp.autSupAutTotMq ? imp.autSupAutTotMq : '0,00'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="1"> <!-- STOCCAGGIO -->
                                <b> IN - Totale (mq): </b> 
                                {{imp.stocInTotMq ? imp.stocInTotMq : '0,00'}}
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <b> Potenzialità Totale (t/anno): </b> 
                                {{imp.potTAnno ? imp.potTAnno : '0,00'}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<div class="callout note">
    <div class="callout-title"> EER </div>
    <table>
        <thead>
            <tr>
                <th> CODICE </th>
                <th> DESCRIZIONE </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cer of risultato.listaCer">
                <td> {{cer.codiceCer}} </td>
                <td> {{cer.descrCer}} </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="callout note">
    <div class="callout-title"> OPERAZIONI </div>
    <table>
        <thead>
            <tr>
                <th> CODICE </th>
                <th> DESCRIZIONE </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let op of risultato.operazioni">
                <td> {{op.codiceOperazione}} </td>
                <td> {{op.descrOperazione}} </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="button-div">
 
    <button class="btn btn-primary" type="button" style="margin-right: 20px;" (click)="onClickTornaRisultati()">
        <svg class="icon icon-white"><use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-arrow-left"></use></svg>
        Torna ai risultati
    </button>
    
    <button
        class="btn btn-primary" (click)="onClickScaricaMatrice()"
    >
        <svg class="icon icon-white"><use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-download"></use></svg>
        Scarica matrice
    </button>
</div>