
<div class="mainPage">
    <div style="overflow-y:auto; overflow-x:auto;" class="header-container ">
        <app-header-interna (pageRequested)="onClickPagina($event)"
            [config]="config$ | async"></app-header-interna>
    </div>
    <div class="container-fluid mainRow">
        <div class="row flex-xl-nowrap">
            <div class="mainContent" style="flex-grow: 10;">
                <app-content *ngIf="isHomepageAttiva"></app-content>
                <app-ricerca-impianti *ngIf="isRicercaImpAttiva"></app-ricerca-impianti>
                <app-dati-cartografici *ngIf="isDatiGeoAttiva" [config]="config$ | async"></app-dati-cartografici>
                <app-criteri-localizzativi *ngIf="isCriteriPaginaAttiva" [config]="config$ | async"></app-criteri-localizzativi>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>

<a href="#" aria-hidden="true" data-attribute="back-to-top" class="back-to-top">
    <svg class="icon icon-light">
        <use xlink:href="bootstrap-lombardia/dist/svg/sprite.svg#it-arrow-up"></use>
    </svg>
</a>

<div class="spinner-wrapper" *ngIf="loading$ | async">
	<app-spinner></app-spinner>
</div>