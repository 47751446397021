import { environment } from "src/environments/environment";

export class URLS_CONSTANTS {


    private HOST_NAME = environment.CONTEXT + '/';

    URL_PROVINCE = this.HOST_NAME + 'province';
    URL_PROVINCE_LOMBARDIA = this.HOST_NAME + 'province' + '/' + 'byIstatRegione/03';

    URL_COMUNI = this.HOST_NAME + 'comuni';
    URL_COMUNI_BY_ISTAT = this.URL_COMUNI + '/byIstatProvincia';
    URL_RICERCA_IMPIANTI = this.HOST_NAME + 'ricercaImpianti';
    URL_SCARICA_REPORT = this.HOST_NAME + 'ricercaImpianti/esporta/excel';
    URL_TIPI_IMPIANTO = this.HOST_NAME + 'tipiImpianto';
    URL_OPERAZIONI = this.HOST_NAME + 'operazioni';

    URL_CER = this.HOST_NAME + 'cer';
    URL_CER_PRIMO_LIVELLO = this.HOST_NAME + 'cer/' + 'primoLivelloByAnno/';
    URL_CER_SECONDO_LIVELLO = this.HOST_NAME + 'cer/' + 'secondoLivelloByAnno/';
    URL_CER_TERZO_LIVELLO = this.HOST_NAME + 'cer/' + 'terzoLivelloByAnno/';


    URL_STATO_ESERCIZIO = this.HOST_NAME + 'statoEsercizio';
    URL_CONFIGURAZIONE = this.HOST_NAME + 'configurazione/';
    URL_SCARICA_MATRICE = this.HOST_NAME + 'ricercaImpianti/esporta/matriceUL?idUL=';
}