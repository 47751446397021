import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatoEsercizio, SiglaColore } from 'src/app/entities/stato-esercizio';
import { CGRService } from 'src/app/services/cgrervice.service';
import { RisultatoRicerca, ListaValori } from '../../entities/risultati-ricerca';
declare var $: any;

@Component({
  selector: 'app-risultati-ricerca-impianti',
  templateUrl: './risultati-ricerca-impianti.component.html',
  styleUrls: ['./risultati-ricerca-impianti.component.css']
})
export class RisultatiRicercaImpiantiComponent implements OnInit, AfterViewInit {

    @Input() risultatiRicerca : RisultatoRicerca[];
    @Input() statiEsercizio: StatoEsercizio[];
    @Output() apriDettaglio : EventEmitter<RisultatoRicerca>= new EventEmitter<RisultatoRicerca>();

    MAX_LISTA: number = 20;

    mappaStatiEsercizio: Map<number, SiglaColore> = new Map<number, SiglaColore>();
    mappaValori: Map<number, ListaValori> = new Map<number, ListaValori>();
    linkGeo: string = "";

    constructor(private service : CGRService) {
    }


    ngOnInit() {
        
        Promise.all([
            this.creaLinkGeo(),
            this.creaMappaStatiEsercizio(),
            this.creaMappaValori()
        ]
        );
    }

    ngAfterViewInit(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    creaMappaValori() {
        for( let risultato of this.risultatiRicerca ) {

            let listaValori: ListaValori = new ListaValori();

            listaValori.listaComuni = risultato.listaComuni.map( (x, index) => {
                let s = x.siglaProvincia ? `${x.nomeComune} (${x.siglaProvincia})` : `${x.nomeComune}`;
                s = index < risultato.listaComuni.length - 1 ? `${s}, ` : s;
                return s;
            });

            listaValori.primoComune = listaValori.listaComuni.length > 1 ? listaValori.listaComuni[0].replace(',', ' ') : listaValori.listaComuni[0];
            
            listaValori.listaImpianti = risultato.tipologieImpianto.map( (x, index) => index < risultato.tipologieImpianto.length - 1 ? `${x.descrTipoImpianto}, ` : x.descrTipoImpianto );
            
            listaValori.listaOperazioni = risultato.operazioni.map( (x, index) => index < risultato.operazioni.length - 1 ? `${x.codiceOperazione}, ` : x.codiceOperazione );

            if ( risultato.listaCer.length > this.MAX_LISTA ) {
                listaValori.listaCer = risultato.listaCer.slice(0, this.MAX_LISTA ).map( x => `${x.codiceCer}, ` );
                listaValori.listaCer.push("...");    
            }
            else {
                listaValori.listaCer = risultato.listaCer.map( (x, index) => index < risultato.listaCer.length - 1 ? `${x.codiceCer},` : x.codiceCer );
            }

            this.mappaValori.set(risultato.idUL, listaValori);

        }
    }

    creaMappaStatiEsercizio() {
        for( let stato of this.statiEsercizio ) {
            this.mappaStatiEsercizio.set(stato.idStatoEsercizio, {sigla: stato.sigla, color: stato.color});
        }
    }

    creaLinkGeo() {
        this.service.getLinkGeo().then( x => {
            this.linkGeo = x.valoreStringa;
        });
    }

    getSiglaById(id: number): string {
        let result = this.mappaStatiEsercizio.get(id);
        if ( !result ) return '';
        return result.sigla;
    }

    getColoreById(id: number): string {
        let result = this.mappaStatiEsercizio.get(id);
        if ( !result ) return '';
        return `#${result.color}a6`;
    }

    onClickApriDettaglio(risultato: RisultatoRicerca) {
        $('[data-toggle="tooltip"]').tooltip('hide');
        this.apriDettaglio.emit(risultato);
    }

}